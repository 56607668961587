import BaseModel from "./base.class";
import { CourseLessonByCourseDetailDto } from "./courseLesson.class";
import { CoursePersonalDetailDto } from "./coursePersonal.class";

export class CourseSectionByCourseDetailDto extends BaseModel<CourseSectionByCourseDetailDto> {
  id?: number;
  index?: number;
  name?: string;
  courseLessons?: CourseLessonByCourseDetailDto[];
  coursePersonalId?: number;
  coursePersonal?: CoursePersonalDetailDto;
}

export type CourseSectionListRes = {
  list: CourseSectionByCourseDetailDto[];
  total: number;
  page: number;
  pageSize: number;
};

export type CourseSectionData = {
  index: number;
  name: string;
  coursePersonalId: number;
};
