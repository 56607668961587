import React, { useEffect, useMemo } from "react";
import styled from "styled-components";

// HOOKS
import useRoute from "hooks/useRoute";
import { useSearchParams } from "react-router-dom";

// COMPONENTS
import { Stack, useTheme } from "@mui/material";
import ProfileSearchNavbar from "./ProfileSearchNavbar";
import CategoryItem from "../items/CategoryItem";

// APIS
import { useCourseCategoryAllByApi } from "apis/courseCategoryApis/useCourseCategoryApis";

// CONSTANTS
import { PageRouteName } from "globals/enums/routes.enum";

const ProfileCategories = React.memo(() => {
  // instances
  const theme = useTheme();
  const { redirect } = useRoute();
  const [searchParams] = useSearchParams();
  const searchName = searchParams.get('name') ? String(searchParams.get('name')) : '';

  // hooks
  const { courseCategoryAll } = useCourseCategoryAllByApi();

  // functions
  const onSubmitSearch = (value: string) => {
    let queryParams = {};
    queryParams['name'] = value;
    redirect(PageRouteName.ProfileCategoriesPage, queryParams);
  };

  // memos
  const categories = useMemo(() => {
    return courseCategoryAll.filter(item => item.name?.toUpperCase().indexOf(searchName.toUpperCase()) !== -1);
  }, [searchName, courseCategoryAll]);

  return (
    <WrapperStyled
      padding={{ xs: '1rem', md: '1rem 2rem' }}
      bgcolor={theme.palette.default.dark}
      width={{ xs: '-webkit-fill-available' }}
      flexDirection="column"
      alignItems="center"
      borderRadius={3}
      rowGap={{ xs: '1.5rem' }}
    >
      <ProfileSearchNavbar
        searchName={searchName}
        onSubmitSearch={onSubmitSearch}
        labelButton="Create category"
        onClickButton={() => redirect(PageRouteName.ProfileCategoriesCreatePage)}
      />
      <Stack flexDirection="column" rowGap={{ xs: '1.5rem' }} width={{ xs: '100%' }}>
        {(categories || [])?.map((item) => (
          <CategoryItem
            key={item.id}
            categoryDetail={item}
          />
        ))}
      </Stack>
    </WrapperStyled>
  );
});

const WrapperStyled = styled(Stack)`

`;

export default ProfileCategories;
