import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

// HOOKS
import useRoute from "hooks/useRoute";
import { useSnackbarContext } from "store/snackbarContext";
import { useSearchParams } from "react-router-dom";
import useLoadingCallback from "hooks/useLoadingCallback";

// COMPONENTS
import { Box, Stack, useTheme } from "@mui/material";
import Button from "components/formControls/Button";
import SelectField from "components/formControls/Select";
import TextField from "components/formControls/TextField";

// CONSTANTS
import { SelectOption } from "globals/constants/types";
import { CourseBuyerData } from "models/classes/courseBuyer.class";

// APIS
import { useCoursePersonalTeacherListByApi } from "apis/coursePersonalApis/useCoursePersonalApis";
import { actionCourseBuyerCreateApi } from "apis/courseBuyerApis/courseBuyerAction";

const ProfileStudentCreate = React.memo(() => {
  // instance
  const { t } = useTranslation();
  const theme = useTheme();
  const { goBack, params } = useRoute();
  const [searchParams] = useSearchParams();
  const getCoursePersonalId = searchParams.get('coursePersonalId') ? Number(searchParams.get('coursePersonalId')) : -1;

  // states
  const [coursePersonalId, setCoursePersonalId] = useState(getCoursePersonalId);
  const [email, setEmail] = useState('');

  // hooks
  const { updateSnackbar } = useSnackbarContext();
  const { coursePersonalTeacherListRes } = useCoursePersonalTeacherListByApi({
    pageSize: 1000,
  });

  const [handleStudentCreate, isLoadingCreate] = useLoadingCallback(async () => {
    try {
      if (coursePersonalId === -1) {
        updateSnackbar({ type: 'error', message: 'Select course', open: true });
        return;
      } else if (!email) {
        updateSnackbar({ type: 'error', message: 'Enter your email student', open: true });
        return;
      }
      const values: CourseBuyerData = {
        email,
        coursePersonalId,
      };
      const data = await actionCourseBuyerCreateApi(values);
      if (data) {
        updateSnackbar({ type: 'success', message: 'Create success', open: true });
        goBack();
      } else {
        updateSnackbar({ type: 'error', message: 'Create fail', open: true });
      }
    } catch (error) {
      console.log('handleDescriptionCreate', error);
    }
  });

  return (
    <WrapperStyled
      padding={{ xs: '2rem' }}
      bgcolor={theme.palette.default.dark}
      width={{ xs: '-webkit-fill-available' }}
      flexDirection={{ xs: 'column' }}
      rowGap={{ xs: '1.5rem' }}
      borderRadius={3}
    >
      <Stack flexDirection={{ xs: 'row' }} justifyContent={{ xs: 'space-between' }} mb={{ xs: '1rem' }}>
        <Box
          fontSize={{ xs: 24, md: 30 }}
          color={theme.palette.text.light}
        >
          {t('Create student')}
        </Box>
        <Button
          color="primary"
          style={{ fontWeight: 300 }}
          disabled={isLoadingCreate}
          onClick={handleStudentCreate}
        >
          {t('Confirm')}
        </Button>
      </Stack>
      <Stack flexDirection={{ xs: 'row' }} columnGap={{ xs: '2rem' }} width={{ xs: '100%' }}>
        <Stack flex={1} rowGap={{ xs: '.5rem' }}>
          <Box color={theme.palette.text.light}>{t('Select course')}</Box>
          <SelectField
            width="100%"
            placeHolder={t('Select course')}
            disabled={params.id ? true : false}
            selectedValue={coursePersonalId}
            options={coursePersonalTeacherListRes?.list.map(item => ({ label: item.name, value: item.id })) as SelectOption[]}
            onChange={(selectedValue) => setCoursePersonalId(Number(selectedValue))}
          />
        </Stack>
        <Stack flex={1} rowGap={{ xs: '.5rem' }}>
          <Box color={theme.palette.text.light}>{t('Email student')}</Box>
          <TextField
            placeholder={t('Enter your email student')}
            background={theme.palette.default.light}
            style={{ width: '100%' }}
            value={email || ''}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </Stack>
      </Stack>
    </WrapperStyled>
  );
});

const WrapperStyled = styled(Stack)``;

export default ProfileStudentCreate;
