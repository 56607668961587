import { isEmpty } from "lodash";
import {
  CourseDescriptionByCourseDetailDto,
  CourseDescriptionData,
} from "models/classes/courseDescription.class";
import {
  fetchCourseDescriptionCreateApi,
  fetchCourseDescriptionDeleteApi,
  fetchCourseDescriptionDetailApi,
  fetchCourseDescriptionListApi,
  fetchCourseDescriptionUpdateApi,
} from "./courseDescriptionFetch";
import { FetchCourseDescriptionParams } from "./courseDescriptionParam";

export const actionCourseDescriptionListApi = async ({
  ...params
}: FetchCourseDescriptionParams) => {
  try {
    const { data } = await fetchCourseDescriptionListApi(params);
    if (!isEmpty(data)) {
      return {
        list: data.list.map((item: CourseDescriptionByCourseDetailDto) =>
          new CourseDescriptionByCourseDetailDto().fromPayload(item)
        ),
        page: data.page,
        pageSize: data.pageSize,
        total: data.total,
      };
    }
  } catch (error) {
    console.log(error);
  }
};

export const actionCourseDescriptionCreateApi = async (
  body: CourseDescriptionData
) => {
  try {
    const { data } = await fetchCourseDescriptionCreateApi(body);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const actionCourseDescriptionDeleteApi = async ({
  ...params
}: FetchCourseDescriptionParams) => {
  try {
    const { data } = await fetchCourseDescriptionDeleteApi(params);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const actionCourseDescriptionDetailApi = async ({
  ...params
}: FetchCourseDescriptionParams) => {
  try {
    const { data } = await fetchCourseDescriptionDetailApi(params);
    if (!isEmpty(data)) {
      return new CourseDescriptionByCourseDetailDto().fromPayload(data);
    }
    return null;
  } catch (error) {
    console.log(error);
  }
};

export const actionCourseDescriptionUpdateApi = async (
  id: number,
  body: CourseDescriptionData
) => {
  try {
    const { data } = await fetchCourseDescriptionUpdateApi(id, body);
    return data;
  } catch (error) {
    console.log(error);
  }
};
