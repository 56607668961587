import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

// FORMS
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from 'react-hook-form';
import * as yup from "yup";

// HOOKS
import { actionAuthUpdateProfileApi } from "apis/authApis/authAction";
import { useDetailProfileByApi } from "apis/authApis/useAuthApis";
import { useSnackbarContext } from "store/snackbarContext";

// COMPONENTS
import { Box, Stack, useTheme } from "@mui/material";
import Button from "components/formControls/Button";
import TextField from "components/formHooks/TextField";

// CONSTANTS
import { isEmpty } from "lodash";
import { UserInfoReq } from "models/classes/auth.class";

const ProfileInformation = React.memo(() => {
  // Instance
  const { t } = useTranslation();
  const theme = useTheme();

  // States
  const informationSchema = yup.object({
    name: yup.string().required(t('This value cannot be blank')).max(50).nullable(),
    zaloContact: yup.string().required(t('This value cannot be blank')).max(100).nullable(),
    address: yup.string().required(t('This value cannot be blank')).max(150).nullable(),
    cccd: yup.string().required(t('This value cannot be blank')).max(50).nullable(),
    socialLink: yup.string().required(t('This value cannot be blank')).max(150).nullable(),
  });

  const { control, handleSubmit, reset } = useForm<any>({
    resolver: yupResolver(informationSchema),
  });

  // refs
  const isLoadingUpdate = useRef(false);

  // hooks
  const { userInfo } = useDetailProfileByApi();
  const { updateSnackbar } = useSnackbarContext();

  const handleUpdate = async (values: UserInfoReq) => {
    if (isLoadingUpdate.current) return;
    isLoadingUpdate.current = true;
    const data = await actionAuthUpdateProfileApi(values);
    if (data) {
      updateSnackbar({ open: true, type: 'success', message: 'Update success' });
    } else {
      updateSnackbar({ open: true, type: 'error', message: 'Update error' });
    }
    isLoadingUpdate.current = false;
  };

  useEffect(() => {
    if (!isEmpty(userInfo)) {
      reset(userInfo);
    }
  }, [userInfo]);

  return (
    <WrapperStyled
      padding={{ xs: '2rem' }}
      bgcolor={theme.palette.default.dark}
      width={{ xs: '-webkit-fill-available' }}
      flexDirection="column"
      rowGap={{ xs: '1.5rem' }}
      borderRadius={3}
    >
      <Stack flexDirection={{ xs: 'row' }} justifyContent={{ xs: 'space-between' }} mb={{ xs: '1rem' }}>
        <Box
          fontSize={{ xs: 24, md: 30 }}
          color={theme.palette.text.light}
        >
          {t('Account information')}
        </Box>
        <Button color="primary" style={{ fontWeight: 300 }} onClick={handleSubmit(handleUpdate)}>{t('Update')}</Button>
      </Stack>

      <Stack flexDirection={{ xs: 'row' }} columnGap={{ xs: '2rem' }} width={{ xs: '100%' }}>
        <Stack flex={1} rowGap={{ xs: '.5rem' }}>
          <Box color={theme.palette.text.light}>{t('Fullname')}</Box>
          <TextField
            control={control}
            name="name"
            placeholder={t('Enter your fullname here')}
            background={theme.palette.default.light}
            style={{ width: '100%' }}
            required
          />
        </Stack>
        <Stack flex={1} rowGap={{ xs: '.5rem' }}>
          <Box color={theme.palette.text.light}>{t('Zalo contact')}</Box>
          <TextField
            control={control}
            name="zaloContact"
            placeholder={t('Enter your zalo contact here')}
            background={theme.palette.default.light}
            style={{ width: '100%' }}
            required
          />
        </Stack>
      </Stack>
      <Stack flexDirection={{ xs: 'row' }} columnGap={{ xs: '2rem' }} width={{ xs: '100%' }}>
        <Stack flex={1} rowGap={{ xs: '.5rem' }}>
          <Box color={theme.palette.text.light}>{t('Address')}</Box>
          <TextField
            control={control}
            name="address"
            placeholder={t('Enter your address here')}
            background={theme.palette.default.light}
            style={{ width: '100%' }}
            required
          />
        </Stack>
        <Stack flex={1} rowGap={{ xs: '.5rem' }}>
          <Box color={theme.palette.text.light}>{t('CCCD')}</Box>
          <TextField
            control={control}
            name="cccd"
            placeholder={t('Enter your cccd here')}
            background={theme.palette.default.light}
            style={{ width: '100%' }}
            required
          />
        </Stack>
      </Stack>
      <Stack flexDirection={{ xs: 'row' }} columnGap={{ xs: '2rem' }} width={{ xs: '100%' }}>
        <Stack flex={1} rowGap={{ xs: '.5rem' }}>
          <Box color={theme.palette.text.light}>{t('Social link')}</Box>
          <TextField
            control={control}
            name="socialLink"
            placeholder={t('Enter your social link here')}
            background={theme.palette.default.light}
            style={{ width: '100%' }}
            required
          />
        </Stack>
        <Stack flex={1} rowGap={{ xs: '.5rem' }}>
        </Stack>
      </Stack>
    </WrapperStyled>
  );
});

const WrapperStyled = styled(Stack)`

`;

export default ProfileInformation;
