import { Obj } from "globals/constants/types";
import { isEmpty } from "lodash";
import {
  CoursePersonalData,
  CoursePersonalDetailDto,
  CoursePersonalNewsDto,
  CoursePersonalVipsDto,
} from "models/classes/coursePersonal.class";
import {
  fetchCourseDetailPublicApi,
  fetchCoursePersonalClientListApi,
  fetchCoursePersonalCreateApi,
  fetchCoursePersonalDetailApi,
  fetchCoursePersonalNewsApi,
  fetchCoursePersonalTeacherListApi,
  fetchCoursePersonalUpdateApi,
  fetchCoursePersonalUpdateByAdminApi,
  fetchCoursePersonalVipsApi,
  fetchCourseUpdateFreeApi,
} from "./coursePersonalFetch";
import { FetchCoursePersonalParams } from "./coursePersonalParam";

export const actionCoursePersonalNewsApi = async ({
  ...params
}: FetchCoursePersonalParams) => {
  try {
    const { data } = await fetchCoursePersonalNewsApi(params);
    if (!isEmpty(data)) {
      return (data || []).map((item: CoursePersonalNewsDto) =>
        new CoursePersonalNewsDto().fromPayload(item as Obj)
      );
    }
  } catch (error) {
    console.log(error);
  }
};

export const actionCoursePersonalVipsApi = async ({
  ...params
}: FetchCoursePersonalParams) => {
  try {
    const { data } = await fetchCoursePersonalVipsApi(params);
    if (!isEmpty(data)) {
      return (data || []).map((item: CoursePersonalVipsDto) =>
        new CoursePersonalVipsDto().fromPayload(item as Obj)
      );
    }
  } catch (error) {
    console.log(error);
  }
};

export const actionCoursePersonalDetailApi = async ({
  ...params
}: FetchCoursePersonalParams) => {
  try {
    const { data } = await fetchCoursePersonalDetailApi(params);
    if (!isEmpty(data)) {
      return new CoursePersonalDetailDto().fromPayload(data || {});
    }
  } catch (error) {
    console.log(error);
  }
};

export const actionCoursePersonalClientListApi = async ({
  ...params
}: FetchCoursePersonalParams) => {
  try {
    const { data } = await fetchCoursePersonalClientListApi(params);
    if (!isEmpty(data)) {
      return {
        list: data.list.map((item: CoursePersonalDetailDto) =>
          new CoursePersonalDetailDto().fromPayload(item)
        ),
        page: data.page,
        pageSize: data.pageSize,
        total: data.total,
      };
    }
  } catch (error) {
    console.log(error);
  }
};

export const actionCoursePersonalTeacherListApi = async ({
  ...params
}: FetchCoursePersonalParams) => {
  try {
    const { data } = await fetchCoursePersonalTeacherListApi(params);
    if (!isEmpty(data)) {
      return {
        list: data.list.map((item: CoursePersonalDetailDto) =>
          new CoursePersonalDetailDto().fromPayload(item)
        ),
        page: data.page,
        pageSize: data.pageSize,
        total: data.total,
      };
    }
  } catch (error) {
    console.log(error);
  }
};

export const actionCoursePersonalCreateApi = async (
  body: CoursePersonalData
) => {
  try {
    const { data } = await fetchCoursePersonalCreateApi(body);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const actionCoursePersonalUpdateApi = async (
  id: number,
  body: CoursePersonalData
) => {
  try {
    const { data } = await fetchCoursePersonalUpdateApi(id, body);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const actionCoursePersonalUpdateByAdminApi = async (
  id: number,
  body: CoursePersonalData
) => {
  try {
    const { data } = await fetchCoursePersonalUpdateByAdminApi(id, body);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const actionCourseUpdateFreeApi = async (id: number) => {
  try {
    const { data } = await fetchCourseUpdateFreeApi(id);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const actionCourseDetailPublicApi = async ({
  ...params
}: FetchCoursePersonalParams) => {
  try {
    const { data } = await fetchCourseDetailPublicApi(params);
    return new CoursePersonalDetailDto().fromPayload(data);
  } catch (error) {
    console.log(error);
  }
};
