import React from "react";
import useRoute from "hooks/useRoute";
import { useTranslation } from "react-i18next";

// COMPONENTS
import { Box, Stack, useTheme } from "@mui/material";
import Button from "components/formControls/Button";

// ICONS
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

// UNTILS
import { PageRouteName } from "globals/enums/routes.enum";
import { CourseDescriptionByCourseDetailDto } from "models/classes/courseDescription.class";

type Props = {
  descriptionDetail: CourseDescriptionByCourseDetailDto | null;
  setSelectedDescription: (section: CourseDescriptionByCourseDetailDto) => void;
};

const DescriptionItem = React.memo(({ descriptionDetail, setSelectedDescription }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { redirect } = useRoute();

  return (
    <Stack
      bgcolor={theme.palette.accent.dark}
      padding={{ xs: '1rem' }}
      borderRadius={2}
      flexDirection={{ xs: 'row' }}
      alignItems={{ xs: 'center' }}
    >
      <Stack flexDirection={{ xs: 'column' }} flex={1} rowGap={{ xs: '.25rem' }}>
        <Stack flexDirection={{ xs: 'row' }} alignItems={{ xs: 'center' }}>
          <Box fontSize={{ xs: 12, md: 14 }} color={theme.palette.text.light}>{t('Name course')}</Box>
        </Stack>
        <Box fontSize={{ xs: 12, md: 14 }} color={theme.palette.text.dark} fontStyle="italic">
          {descriptionDetail?.courseName}
        </Box>
      </Stack>
      <Stack flexDirection={{ xs: 'column' }} flex={1} rowGap={{ xs: '.25rem' }}>
        <Stack flexDirection={{ xs: 'row' }} alignItems={{ xs: 'center' }}>
          <Box fontSize={{ xs: 12, md: 14 }} color={theme.palette.text.light}>{t('Name description')}</Box>
        </Stack>
        <Box fontSize={{ xs: 12, md: 14 }} color={theme.palette.text.dark} fontStyle="italic">
          {descriptionDetail?.name}
        </Box>
      </Stack>
      <Stack
        flexDirection={{ xs: 'row' }}
        justifyContent={{ xs: 'center' }}
        alignItems={{ xs: 'center' }}
        flex={.4}
        rowGap={{ xs: '.25rem' }}
        columnGap={{ xs: '1rem' }}
      >
        <Button
          onClick={() => setSelectedDescription(descriptionDetail!)}
          color="success"
          width="100%"
          style={{ fontWeight: 300, padding: '.5rem' }}
        >
          <DeleteIcon style={{ color: theme.palette.text.light, fontSize: 24 }} />
        </Button>
        <Button
          onClick={() => redirect(`${PageRouteName.ProfileDescriptionUpdatePage}/${descriptionDetail?.id}`, {
            coursePersonalId: descriptionDetail?.coursePersonalId
          })}
          color="primary"
          width="100%"
          style={{ fontWeight: 300, padding: '.5rem' }}
        >
          <EditIcon style={{ color: theme.palette.text.light, fontSize: 24 }} />
        </Button>
      </Stack>
    </Stack >
  );
});

export default DescriptionItem;
