import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

// HOOKS
import useRoute from "hooks/useRoute";
import { useSearchParams } from "react-router-dom";
import { useSnackbarContext } from "store/snackbarContext";

// FORMS
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from 'react-hook-form';
import * as yup from "yup";

// COMPONENTS
import { Box, Stack, useTheme } from "@mui/material";
import Button from "components/formControls/Button";
import SelectField from "components/formControls/Select";
import TextField from "components/formHooks/TextField";

// APIS
import { actionCourseLessonCreateApi, actionCourseLessonUpdateApi } from "apis/courseLessonApis/courseLessonAction";
import { useCoursePersonalTeacherListByApi } from "apis/coursePersonalApis/useCoursePersonalApis";
import { useCourseSectionAllByApi } from "apis/courseSectionApis/useCourseSectionApis";

// CONSTANTS
import { useLessonDetailAdminByApi } from "apis/courseLessonApis/useCourseLessonApis";
import { SelectOption } from "globals/constants/types";
import { IsCreate, IsEdit, VideoTypeEnum, VideoTypeOptions } from "globals/enums/courses.enum";
import { CourseLessonData } from "models/classes/courseLesson.class";

const ProfileLessonCreate = React.memo(() => {
  // instance
  const { t } = useTranslation();
  const theme = useTheme();
  const { goBack, params } = useRoute();
  const isView = params.id ? IsEdit : IsCreate;
  const [searchParams] = useSearchParams();
  const getCoursePersonalId = searchParams.get('coursePersonalId') ? Number(searchParams.get('coursePersonalId')) : -1;

  // states
  const [coursePersonalId, setCoursePersonalId] = useState(getCoursePersonalId);
  const [courseSectionId, setCourseSectionId] = useState(-1);
  const [videoType, setVideoType] = useState(VideoTypeEnum.EMBED);

  // hooks
  const { updateSnackbar } = useSnackbarContext();
  const { coursePersonalTeacherListRes } = useCoursePersonalTeacherListByApi({
    pageSize: 1000,
  });
  const { sectionAll } = useCourseSectionAllByApi({ coursePersonalId });
  const { lessonDetailByAdmin } = useLessonDetailAdminByApi({ courseLessonId: params.id, coursePersonalId });

  // forms
  const lessonSchema = yup.object({
    name: yup.string().required('This value cannot be blank').nullable(),
    content: yup.string().required('This value cannot be blank').nullable(),
    time: yup.string().required('This value cannot be blank').nullable(),
    videoUrl: yup.string().required('This value cannot be blank').nullable(),
  });

  const { control, handleSubmit, reset } = useForm<any>({
    resolver: yupResolver(lessonSchema),
  });

  const handleLessonCreate = async (values: CourseLessonData) => {
    try {
      if (coursePersonalId === -1) {
        updateSnackbar({ type: 'error', message: 'Select course', open: true });
        return;
      } else if (courseSectionId === -1) {
        updateSnackbar({ type: 'error', message: 'Select section', open: true });
        return;
      }
      values = {
        ...values,
        coursePersonalId,
        courseSectionId,
        videoType,
      };
      const data = await actionCourseLessonCreateApi(values);
      if (data) {
        updateSnackbar({ type: 'success', message: 'Create success', open: true });
        goBack();
      } else {
        updateSnackbar({ type: 'error', message: 'Create fail', open: true });
      }
    } catch (error) {
      console.log('handleLessonCreate', error);
    }
  };

  const handleLessonUpdate = async (values: CourseLessonData) => {
    try {
      if (courseSectionId === -1) {
        updateSnackbar({ type: 'error', message: 'Select section', open: true });
        return;
      }
      values = {
        ...values,
        coursePersonalId,
        courseSectionId,
        videoType,
      };
      const data = await actionCourseLessonUpdateApi(params.id, values);
      if (data) {
        updateSnackbar({ type: 'success', message: 'Update success', open: true });
        goBack();
      } else {
        updateSnackbar({ type: 'error', message: 'Update fail', open: true });
      }
    } catch (error) {
      console.log('handleLessonUpdate', error);
    }
  };

  // effects
  useEffect(() => {
    if (isView === IsEdit) {
      reset({
        name: lessonDetailByAdmin?.name,
        content: lessonDetailByAdmin?.content,
        time: lessonDetailByAdmin?.time,
        videoUrl: lessonDetailByAdmin?.videoUrl,
      });
      setCoursePersonalId(lessonDetailByAdmin?.coursePersonalId!);
      setCourseSectionId(lessonDetailByAdmin?.courseSectionId!);
      setVideoType(lessonDetailByAdmin?.videoType! as VideoTypeEnum);
    }
  }, [lessonDetailByAdmin]);

  return (
    <WrapperStyled
      padding={{ xs: '2rem' }}
      bgcolor={theme.palette.default.dark}
      width={{ xs: '-webkit-fill-available' }}
      flexDirection={{ xs: 'column' }}
      rowGap={{ xs: '1.5rem' }}
      borderRadius={3}
    >
      <Stack flexDirection={{ xs: 'row' }} justifyContent={{ xs: 'space-between' }} mb={{ xs: '1rem' }}>
        <Box
          fontSize={{ xs: 24, md: 30 }}
          color={theme.palette.text.light}
        >
          {t('Create lesson')}
        </Box>
        <Button
          color="primary"
          style={{ fontWeight: 300 }}
          onClick={isView === IsEdit ? handleSubmit(handleLessonUpdate) : handleSubmit(handleLessonCreate)}
        >
          {t('Confirm')}
        </Button>
      </Stack>
      <Stack flexDirection={{ xs: 'row' }} columnGap={{ xs: '2rem' }} width={{ xs: '100%' }}>
        <Stack flex={1} rowGap={{ xs: '.5rem' }}>
          <Box color={theme.palette.text.light}>{t('Select course')}</Box>
          <SelectField
            width="100%"
            placeHolder={t('Select course')}
            disabled={params.id ? true : false}
            selectedValue={coursePersonalId}
            options={coursePersonalTeacherListRes?.list.map(item => ({ label: item.name, value: item.id })) as SelectOption[]}
            onChange={(selectedValue) => {
              setCoursePersonalId(Number(selectedValue));
              setCourseSectionId(-1);
            }}
          />
        </Stack>
        <Stack flex={1} rowGap={{ xs: '.5rem' }}>
          <Box color={theme.palette.text.light}>{t('Select section')}</Box>
          <SelectField
            width="100%"
            placeHolder={t('Select section')}
            selectedValue={courseSectionId}
            options={(sectionAll || []).map(item => ({ label: item.name, value: item.id })) as SelectOption[]}
            onChange={(selectedValue) => setCourseSectionId(Number(selectedValue))}
          />
        </Stack>
      </Stack>
      <Stack flexDirection={{ xs: 'row' }} columnGap={{ xs: '2rem' }} width={{ xs: '100%' }}>
        <Stack flex={1} rowGap={{ xs: '.5rem' }}>
          <Box color={theme.palette.text.light}>{t('Name lesson')}</Box>
          <TextField
            control={control}
            name="name"
            maxLength={50}
            placeholder={t('Enter your name lesson')}
            background={theme.palette.default.light}
            style={{ width: '100%' }}
            required
          />
        </Stack>
        <Stack flex={1} rowGap={{ xs: '.5rem' }}>
          <Box color={theme.palette.text.light}>{t('Content lesson')}</Box>
          <TextField
            control={control}
            name="content"
            maxLength={250}
            placeholder={t('Enter your content lesson')}
            background={theme.palette.default.light}
            style={{ width: '100%' }}
            required
          />
        </Stack>
      </Stack>
      <Stack flexDirection={{ xs: 'row' }} columnGap={{ xs: '2rem' }} width={{ xs: '100%' }}>
        <Stack flex={1} rowGap={{ xs: '.5rem' }}>
          <Box color={theme.palette.text.light}>{t('Select video type')}</Box>
          <SelectField
            width="100%"
            placeHolder={t('Select video type')}
            selectedValue={videoType}
            options={VideoTypeOptions.map(item => ({ label: item.label, value: item.value })) as SelectOption[]}
            onChange={(selectedValue) => setVideoType(selectedValue as VideoTypeEnum)}
          />
        </Stack>
        <Stack flex={1} rowGap={{ xs: '.5rem' }}>
          <Box color={theme.palette.text.light}>{t('Video url')}</Box>
          <TextField
            control={control}
            name="videoUrl"
            maxLength={250}
            placeholder={t('Enter your video url')}
            background={theme.palette.default.light}
            style={{ width: '100%' }}
            required
          />
        </Stack>
      </Stack>
      <Stack flexDirection={{ xs: 'row' }} columnGap={{ xs: '2rem' }} width={{ xs: '100%' }}>
        <Stack flex={1} rowGap={{ xs: '.5rem' }}>
          <Box color={theme.palette.text.light}>{t('Time')}</Box>
          <TextField
            control={control}
            name="time"
            maxLength={10}
            placeholder={t('Enter your time')}
            background={theme.palette.default.light}
            style={{ width: '100%' }}
            required
          />
        </Stack>
        <Stack flex={1} rowGap={{ xs: '.5rem' }}>
        </Stack>
      </Stack>
    </WrapperStyled>
  );
});

const WrapperStyled = styled(Stack)`

`;

export default ProfileLessonCreate;
