import React from "react";

// COMPONENTS
import { Box, Stack, useTheme } from "@mui/material";
import Button from "components/formControls/Button";

// ICONS
import LockResetIcon from '@mui/icons-material/LockReset';
import AirplanemodeActiveIcon from '@mui/icons-material/AirplanemodeActive';

// UNTILS
import { UserDto } from "models/classes/auth.class";
import { useTranslation } from "react-i18next";

type Props = {
  userDetail: UserDto | null;
  setUpdatePassword: (user: UserDto) => void;
  setUpdateActive: (user: UserDto) => void;
};

const UserItem = React.memo(({ userDetail, setUpdatePassword, setUpdateActive }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Stack
      bgcolor={theme.palette.accent.dark}
      padding={{ xs: '1rem' }}
      borderRadius={2}
      flexDirection={{ xs: 'row' }}
      alignItems={{ xs: 'center' }}
      columnGap={{ xs: '1rem' }}
    >
      <Stack flexDirection={{ xs: 'column' }} flex={1} rowGap={{ xs: '.25rem' }}>
        <Stack flexDirection={{ xs: 'row' }} alignItems={{ xs: 'center' }}>
          <Box fontSize={{ xs: 12, md: 14 }} color={theme.palette.text.light}>{t('Fullname')}</Box>
        </Stack>
        <Box fontSize={{ xs: 12, md: 14 }} color={theme.palette.text.dark} fontStyle="italic">
          {userDetail?.name}
        </Box>
      </Stack>
      <Stack flexDirection={{ xs: 'column' }} flex={1} rowGap={{ xs: '.25rem' }}>
        <Stack flexDirection={{ xs: 'row' }} alignItems={{ xs: 'center' }}>
          <Box fontSize={{ xs: 12, md: 14 }} color={theme.palette.text.light}>{t('Email')}</Box>
        </Stack>
        <Box fontSize={{ xs: 12, md: 14 }} color={theme.palette.text.dark} fontStyle="italic">
          {userDetail?.email}
        </Box>
      </Stack>
      <Stack flexDirection={{ xs: 'column' }} flex={1} rowGap={{ xs: '.25rem' }}>
        <Stack flexDirection={{ xs: 'row' }} alignItems={{ xs: 'center' }}>
          <Box fontSize={{ xs: 12, md: 14 }} color={theme.palette.text.light}>{t('Status')}</Box>
        </Stack>
        <Box fontSize={{ xs: 12, md: 14 }} color={theme.palette.text.dark} fontStyle="italic">
          {userDetail?.isActive ? t('Active') : t('Inactive')}
        </Box>
      </Stack>
      <Stack flexDirection={{ xs: 'column' }} flex={1} rowGap={{ xs: '.25rem' }}>
        <Stack flexDirection={{ xs: 'row' }} alignItems={{ xs: 'center' }}>
          <Box fontSize={{ xs: 12, md: 14 }} color={theme.palette.text.light}>{t('Role')}</Box>
        </Stack>
        <Box fontSize={{ xs: 12, md: 14 }} color={theme.palette.text.dark} fontStyle="italic">
          {userDetail?.role?.name}
        </Box>
      </Stack>
      <Stack
        flexDirection={{ xs: 'row' }}
        justifyContent={{ xs: 'center' }}
        alignItems={{ xs: 'center' }}
        flex={.4}
        rowGap={{ xs: '.25rem' }}
        columnGap={{ xs: '1rem' }}
      >
        <Button
          color="success"
          width="100%"
          style={{ fontWeight: 300, padding: '.5rem' }}
          onClick={() => setUpdatePassword(userDetail!)}
        >
          <LockResetIcon style={{ color: theme.palette.text.light, fontSize: 28 }} />
        </Button>
        <Button
          color="primary"
          width="100%"
          style={{ fontWeight: 300, padding: '.5rem' }}
          onClick={() => setUpdateActive(userDetail!)}
        >
          <AirplanemodeActiveIcon style={{ color: theme.palette.text.light, fontSize: 28 }} />
        </Button>
      </Stack>
    </Stack >
  );
});

export default UserItem;
