import { parseRequestParams } from "utils/dataMappers";
import { publicRequest } from "../configs/requestApis";
import { FetchCourseDescriptionParams } from "./courseDescriptionParam";
import { CourseDescriptionData } from "models/classes/courseDescription.class";

export const fetchCourseDescriptionListApi = async ({
  newAbortSignal,
  searchName,
  page,
  pageSize,
  ...params
}: FetchCourseDescriptionParams) =>
  publicRequest.get(
    `/course/description/list`,
    parseRequestParams(
      params,
      { searchName, page, pageSize, newAbortSignal },
      true
    )
  ) as any;

export const fetchCourseDescriptionCreateApi = async (
  body: CourseDescriptionData
) => publicRequest.post(`/course/description/create`, body) as any;

export const fetchCourseDescriptionDeleteApi = async ({
  courseDescriptionId,
  coursePersonalId,
}: FetchCourseDescriptionParams) =>
  publicRequest.delete(
    `/course/description/delete/${courseDescriptionId}?coursePersonalId=${coursePersonalId}`
  ) as any;

export const fetchCourseDescriptionDetailApi = async ({
  courseDescriptionId,
  newAbortSignal,
  ...params
}: FetchCourseDescriptionParams) =>
  publicRequest.get(
    `/course/description/detail/${courseDescriptionId}`,
    parseRequestParams(params, { newAbortSignal }, true)
  ) as any;

export const fetchCourseDescriptionUpdateApi = async (
  id: number,
  body: CourseDescriptionData
) => publicRequest.put(`/course/description/update/${id}`, body) as any;
