import BaseModel from "./base.class";

export class CourseCategoryListDto extends BaseModel<CourseCategoryListDto> {
  id?: number;
  name?: string;
  image?: string;
  numberOfCourses?: number;
}

export class CourseCategoryAllDto extends BaseModel<CourseCategoryListDto> {
  id?: number;
  name?: string;
  image?: string;
}

export type CourseCategoryData = {
  name: string;
  image: string;
};
