import React, { useState } from "react";
import styled from "styled-components";

// HOOKS
import { useCoursePersonalTeacherListByApi } from "apis/coursePersonalApis/useCoursePersonalApis";
import useRoute from "hooks/useRoute";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { useSnackbarContext } from "store/snackbarContext";

// COMPONENTS
import { Box, Stack, useTheme } from "@mui/material";
import DialogSlide from "components/modals/DialogSlide";
import PaginationItem from "views/PaginationItem";
import CourseItem from "../items/CourseItem";
import ProfileSearchNavbar from "./ProfileSearchNavbar";

// UNTILS
import { actionCoursePersonalUpdateByAdminApi, actionCourseUpdateFreeApi } from "apis/coursePersonalApis/coursePersonalAction";
import { StorageKey } from "globals/constants/storage";
import { Roles } from "globals/enums/auth.enum";
import { CoursePersonalStatus } from "globals/enums/courses.enum";
import { PageRouteName } from "globals/enums/routes.enum";
import { CoursePersonalDetailDto } from "models/classes/coursePersonal.class";
import { getStorage } from "services/storageService";

const ProfileSaller = React.memo(() => {
  // instances
  const { t } = useTranslation();
  const theme = useTheme();
  const { redirect } = useRoute();
  const [searchParams] = useSearchParams();
  const searchName = searchParams.get('name') ? String(searchParams.get('name')) : '';
  const page = searchParams.get('page') ? Number(searchParams.get('page')) : 1;
  const userRole = getStorage(StorageKey.Role) || Roles.TEACHER;

  // states
  const [isDialogSaller, setIsDialogSaller] = useState(false);
  const [selectedCourse, setSelectedCourse] = useState<CoursePersonalDetailDto | null>(null);

  // hooks
  const { coursePersonalTeacherListRes, fetchCoursePersonalTeacherListByApi } = useCoursePersonalTeacherListByApi({
    searchName,
    page,
  });
  const { updateSnackbar } = useSnackbarContext();

  // functions
  const onSubmitSearch = (value: string) => {
    let queryParams = {};
    queryParams['page'] = 1;
    queryParams['name'] = value;
    redirect(PageRouteName.ProfileSallerPage, queryParams);
  };

  const handleSallerCourse = async () => {
    try {
      if (selectedCourse?.isPublic) {
        setIsDialogSaller(false);
        const data = await actionCourseUpdateFreeApi(selectedCourse.id!);
        if (data) {
          updateSnackbar({ type: 'success', message: 'Update success', open: true });
          fetchCoursePersonalTeacherListByApi();
        } else {
          updateSnackbar({ type: 'error', message: 'Update fail', open: true });
        }
      } else if (!selectedCourse?.isPublic) {
        setIsDialogSaller(false);
      }
    } catch (error) {
      console.log('handleSallerCourse', error);
    }
  };

  const handleUpdateCourseByAdmin = async () => {
    try {
      if (selectedCourse?.id) {
        setIsDialogSaller(false);
        const data = await actionCoursePersonalUpdateByAdminApi(selectedCourse.id!, {
          status: selectedCourse.status === CoursePersonalStatus.ONSALE
            ? CoursePersonalStatus.OFFSALE
            : CoursePersonalStatus.ONSALE
        });
        if (data) {
          updateSnackbar({ type: 'success', message: 'Update success', open: true });
          fetchCoursePersonalTeacherListByApi();
        } else {
          updateSnackbar({ type: 'error', message: 'Update fail', open: true });
        }
      }
    } catch (error) {
      console.log('handleUpdateCourseByAdmin', error);
    }
  };

  return (
    <WrapperStyled
      padding={{ xs: '1rem', md: '1rem 2rem' }}
      bgcolor={theme.palette.default.dark}
      width={{ xs: '-webkit-fill-available' }}
      flexDirection="column"
      alignItems="center"
      borderRadius={3}
      rowGap={{ xs: '1.5rem' }}
    >
      <ProfileSearchNavbar
        searchName={searchName}
        onSubmitSearch={onSubmitSearch}
        labelButton={userRole === Roles.TEACHER ? "Create course" : ''}
        onClickButton={() => redirect(PageRouteName.ProfileSallerCreatePage)}
      />
      <Stack flexDirection="column" rowGap={{ xs: '1.5rem' }} width={{ xs: '100%' }}>
        {(coursePersonalTeacherListRes?.list || [])?.map((item) => (
          <CourseItem
            key={item.id}
            coursePersonal={item}
            setIsDialogSaller={(value) => setIsDialogSaller(value)}
            setSelectedCourse={(course: CoursePersonalDetailDto) => setSelectedCourse(course)}
            showBtnEdit={userRole === Roles.TEACHER ? true : false}
          />
        ))}
      </Stack>
      <DialogSlide
        label="Activate status"
        open={isDialogSaller}
        onClose={(value) => setIsDialogSaller(value)}
        onConfirm={userRole === Roles.TEACHER ? handleSallerCourse : handleUpdateCourseByAdmin}
      >
        <Stack
          flexDirection={{ xs: 'column' }}
          rowGap={{ xs: '1rem' }}
          fontWeight={300}
          color={theme.palette.text.light}
        >
          {selectedCourse?.isPublic
            ? t('Pricing your course is Free, you are free to activate the update status, do you want to update it?')
            : userRole === Roles.TEACHER
              ? t('Your course pricing is fee-based, you cannot freely activate active status, please contact Admin to activate course status.')
              : t('Do you want to update it')
          }
          {(!selectedCourse?.isPublic && userRole === Roles.TEACHER) && (
            <Stack
              flexDirection={{ xs: 'column' }}
              rowGap={{ xs: '1rem' }}
            >
              <Box fontSize={{ xs: 14, md: 16 }} color={theme.palette.text.light}>
                ✅ {t('Admin')}: {'Nguyen Kim Tien' || ''}
              </Box>
              <Box fontSize={{ xs: 14, md: 16 }} color={theme.palette.text.light}>
                ✅ {t('Zalo')}: {'0763557366' || ''}
              </Box>
              <Box fontSize={{ xs: 14, md: 16 }} color={theme.palette.text.light}>
                ✅ {t('Social')}: <a
                  href={'https://www.facebook.com/nkt.9920/'}
                  target="_blank"
                  style={{ color: theme.palette.text.light, textDecoration: 'none' }}
                >
                  👉 {t('View now')}
                </a>
              </Box>
            </Stack>
          )}
        </Stack>
      </DialogSlide>
      <PaginationItem
        page={page}
        setPage={(page) => redirect(PageRouteName.ProfileSallerPage, { page, name: searchName })}
        totalPage={Math.ceil(Number(coursePersonalTeacherListRes?.total) / Number(coursePersonalTeacherListRes?.pageSize)) || 1}
      />
    </WrapperStyled>
  );
});

const WrapperStyled = styled(Stack)`

`;

export default ProfileSaller;
