import React from "react";
import styled from "styled-components";

// COMPONENTS
import { Pagination, useTheme } from "@mui/material";

type Props = {
  page?: number;
  setPage?: (page: number) => void;
  totalPage?: number;
}

const PaginationItem = React.memo(({
  page = 1,
  setPage = () => { },
  totalPage = 1,
}: Props) => {
  const theme = useTheme();
  if (totalPage === 1) return null;
  return (
    <WrapperStyled
      page={page}
      count={totalPage}
      shape="rounded"
      sx={{
        "& .MuiPaginationItem-page": { color: theme.palette.text.light, fontFamily: '"Montserrat", sans-serif' },
        "& .Mui-selected": { background: 'linear-gradient(#6C40C9, #804FA0)' },
        "& .MuiPagination-ul li:last-child button": { color: theme.palette.text.light },
        "& .MuiPagination-ul li:first-of-type button": { color: theme.palette.text.light },
        "& .MuiPaginationItem-root": { color: theme.palette.text.light, fontFamily: '"Montserrat", sans-serif' },
      }}
      onChange={(e, value) => setPage(value)}
    />
  );
});

const WrapperStyled = styled(Pagination)`

`

export default PaginationItem;
