import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

// COMPONENTS
import { Stack, useTheme } from "@mui/material";
import Button from "components/formControls/Button";
import TextField from "components/formControls/TextField";

// ICONS
import SearchIcon from '@mui/icons-material/Search';

type Props = {
  searchName?: string;
  labelButton?: string;
  onClickButton?: () => void;
  onSubmitSearch?: (value: string) => void;
};

const ProfileSearchNavbar = React.memo(({
  searchName,
  labelButton,
  onClickButton,
  onSubmitSearch,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  // states
  const [txtSearch, setTxtSearch] = useState(searchName);

  return (
    <WrapperStyled
      bgcolor={theme.palette.default.dark}
      width={{ xs: '-webkit-fill-available' }}
      height={{ xs: '50px' }}
      flexDirection="row"
      alignItems="center"
      borderRadius={3}
    >
      <Stack
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        width={{ xs: '100%' }}
      >
        <Stack flexDirection="row" columnGap={2}>
          <TextField
            required={false}
            placeholder={t('Enter search here!')}
            background={theme.palette.default.light}
            value={txtSearch}
            onChange={(e) => setTxtSearch(e.target.value)}
          />
          <Button color="primary" style={{ fontWeight: 300 }} onClick={() => onSubmitSearch && onSubmitSearch(txtSearch!)}>
            <SearchIcon style={{ color: theme.palette.text.light }} />
          </Button>
        </Stack>
        {labelButton && (
          <Button color="primary" style={{ fontWeight: 300 }} onClick={() => onClickButton && onClickButton()}>
            {t(`${labelButton}`)}
          </Button>
        )}
      </Stack>
    </WrapperStyled>
  );
});

const WrapperStyled = styled(Stack)`
`;

export default ProfileSearchNavbar;
