export enum PageRouteName {
  DashboardPage = "/",
  CourseStorePage = "/courses",
  CourseCategoryStorePage = "/courses/categories",
  BlogPage = "/blogs",
  ProjectPage = "/projects",
  ProjectDetailPage = "/projects/detail",
  SignInPage = "/signin",
  SignUpPage = "/signup",
  PaymentPage = "/payment",
  CourseViewPage = "/courses/view",
  ContactPage = "/contact",
  LogoutPage = "/logout",

  // Profile
  ProfilePage = "/profile",
  ProfileInformationPage = "/profile/information",
  ProfilePasswordPage = "/profile/password",
  ProfilePurchasedPage = "/profile/purchased",

  ProfileLessonPage = "/profile/lesson",
  ProfileLessonCreatePage = "/profile/lesson/create",
  ProfileLessonUpdatePage = "/profile/lesson/update",

  ProfileSectionPage = "/profile/section",
  ProfileSectionCreatePage = "/profile/section/create",
  ProfileSectionUpdatePage = "/profile/section/update",

  ProfileSallerPage = "/profile/saller",
  ProfileSallerCreatePage = "/profile/saller/create",
  ProfileSallerUpdatePage = "/profile/saller/update",

  ProfileDescriptionPage = "/profile/description",
  ProfileDescriptionCreatePage = "/profile/description/create",
  ProfileDescriptionUpdatePage = "/profile/description/update",

  ProfileManageStudentPage = "/profile/student",
  ProfileManageStudentCreatePage = "/profile/student/create",

  // Admin
  ProfileUserPage = "/profile/user",
  ProfileCategoriesPage = "/profile/categories",
  ProfileCategoriesCreatePage = "/profile/categories/create",
  ProfileCategoriesUpdatePage = "/profile/categories/update",

  // Admin Management
  DashboardManagePage = "/admin",
  UserManagePage = "/admin/users",
  AccountManagePage = "/admin/accounts",
  GamePlayManagePage = "/admin/gameplays",
}
