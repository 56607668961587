import { Suspense, lazy } from "react";
import styled from "styled-components";

// ICONS
import CharacterBanner from 'assets/banner-khoahoc.webp';

// COMPONENTS
import { Stack } from "@mui/material";
import { useCoursePersonalDetailByApi } from "apis/coursePersonalApis/useCoursePersonalApis";
import useRoute from "hooks/useRoute";
import CourseComment from "./sections/CourseComment";
const BannerLayout = lazy(() => import("layouts/BannerLayout"));
const CourseContent = lazy(() => import("./sections/CourseContent"));
const CourseBody = lazy(() => import("./sections/CourseBody"));

const CourseDetailPage = () => {
  // instances
  const { params: { id: coursePersonalId } } = useRoute();

  // hooks api
  const { coursePersonalDetail } = useCoursePersonalDetailByApi(coursePersonalId);

  return (
    <WrapperStyled
      padding={{ xs: '3rem 2rem' }}
      width={{ xs: 'auto', md: '100%' }}
      flexDirection={{ xs: 'column' }}
      rowGap={{ xs: '3rem' }}
    >
      <Suspense>
        <BannerLayout
          height={{ xs: '10rem', md: '12rem' }}
          title="Course detail"
          subTitle="Choose your favorite course!"
          imageFile={CharacterBanner}
          widthImage={{ xs: '150px', md: '200px' }}
          heightImage={{ xs: '150px' }}
        />
        <CourseContent coursePersonalDetail={coursePersonalDetail!} />
        <CourseBody coursePersonalDetail={coursePersonalDetail!} />
        <CourseComment coursePersonalId={coursePersonalId} />
      </Suspense>
    </WrapperStyled>
  );
};

const WrapperStyled = styled(Stack)`
`;


export default CourseDetailPage;
