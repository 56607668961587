import {
  SessionKey,
  SessionValues,
  StorageKey,
  StorageValues,
  DevelopDebugKey,
} from 'globals/constants/storage';
import { isArray, isObject } from 'utils/typeHelpers';

const stringifyValue = (value: any): string => {
  if (typeof value === 'string') return value;
  if (typeof value === 'number') return value.toString();
  if (value === null) return '';

  return JSON.stringify(value);
}

const parseValue = (value: any) => {
  if (value === null) return undefined;

  try {
    return JSON.parse(value);
  } catch (e) {
    return value;
  }
}

type GetSessionFunc = {
  (key: SessionKey): SessionValues[typeof key];
  (keys: SessionKey[]): Record<(typeof keys)[number], SessionValues[typeof keys[number]]>;
}

// @ts-ignore
export const getSession: GetSessionFunc = key => {
  if (isArray(key)) {
    return key.reduce((previousValues, currentKey) => ({
      ...previousValues,
      [currentKey]: parseValue(sessionStorage.getItem(currentKey)),
    }), {});
  }

  return parseValue(sessionStorage.getItem(key));
}

type SetSessionFunc = {
  (key: SessionKey, value: SessionValues[typeof key]): void;
  (key: Partial<SessionValues>): void;
}

// @ts-ignore
export const setSession: SetSessionFunc = (key, value): void => {
  if (isObject(key)) {
    Object.keys(key).forEach(k => {
      sessionStorage.setItem(k, stringifyValue(key[k as SessionKey]!));
    })
  } else {
    sessionStorage.setItem(key, stringifyValue(value!));
  }
}

export const hasSession = (key: SessionKey): boolean => !!sessionStorage.getItem(key);

export const removeSession = (key: SessionKey | SessionKey[] | true) => {
  // if input param is true, remove all defined session
  if (key === true) {
    const allKeys = Object.values(SessionKey);
    allKeys.forEach(k => sessionStorage.removeItem(k))
    return;
  }

  // if input param is an array, remove all session of array
  if (isArray(key)) {
    key.forEach(k => sessionStorage.removeItem(k));
    return;
  }

  sessionStorage.removeItem(key);
}

type GetStorageFunc = {
  (key: StorageKey): StorageValues[typeof key];
  (keys: StorageKey[]): Record<(typeof keys)[number], StorageValues[typeof keys[number]]>;
}

// @ts-ignore
export const getStorage: GetStorageFunc = key => {
  if (isArray(key)) {
    return key.reduce((previousValues, currentKey) => ({
      ...previousValues,
      [currentKey]: parseValue(localStorage.getItem(currentKey)),
    }), {});
  }

  return parseValue(localStorage.getItem(key));
}

type SetStorageFunc = {
  (key: StorageKey, value: StorageValues[typeof key]): void;
  (key: Partial<StorageValues>): void;
}

// @ts-ignore
export const setStorage: SetStorageFunc = (key, value): void => {
  if (isObject(key)) {
    Object.keys(key).forEach(k => {
      localStorage.setItem(k, stringifyValue(key[k as StorageKey]!));
    })
  } else {
    localStorage.setItem(key, stringifyValue(value!));
  }
}

export const hasStorage = (key: StorageKey): boolean => !!localStorage.getItem(key);

export const removeStorage = (key: StorageKey | StorageKey[] | true) => {
  // if input param is true, remove all defined session
  if (key === true) {
    const allKeys = Object.values(StorageKey);
    allKeys.forEach(k => localStorage.removeItem(k))
    return;
  }

  // if input param is an array, remove all session of array
  if (isArray(key)) {
    key.forEach(k => localStorage.removeItem(k));
    return;
  }

  localStorage.removeItem(key);
}

export const getDebugValue = (key: DevelopDebugKey, defaultValue?: any) =>
  parseValue(localStorage.getItem(key)) ?? defaultValue;