import styled from "styled-components";

// ICONS
import CharacterBanner from 'assets/banner-khoahoc.webp';

// COMPONENTS
import { Stack } from "@mui/material";
import BannerLayout from "layouts/BannerLayout";
import AdminContact from "./sections/AdminContact";
import TeacherContact from "./sections/TeacherContact";
import { useCoursePersonalDetailByApi } from "apis/coursePersonalApis/useCoursePersonalApis";
import { useSearchParams } from "react-router-dom";

const ContactPage = () => {
  // instances
  const [searchParams] = useSearchParams();
  const coursePersonalId = searchParams.get('coursePersonalId') ? Number(searchParams.get('coursePersonalId')) : 0;

  const { coursePersonalDetail } = useCoursePersonalDetailByApi(coursePersonalId);

  return (
    <WrapperStyled
      padding={{ xs: '3rem 2rem' }}
      width={{ xs: 'auto', md: '100%' }}
      flexDirection={{ xs: 'column' }}
      rowGap={{ xs: '2rem' }}
    >
      <BannerLayout
        height={{ xs: '10rem', md: '12rem' }}
        title="Contact Info"
        subTitle="Please contact Teacher or admin for consulting support"
        imageFile={CharacterBanner}
        widthImage={{ xs: '150px', md: '200px' }}
        heightImage={{ xs: '150px' }}
      />
      <Stack
        flexDirection={{ xs: 'row' }}
        columnGap={{ xs: '2rem' }}
      >
        <TeacherContact coursePersonalDetail={coursePersonalDetail} />
        <AdminContact />
      </Stack>
    </WrapperStyled>
  )
};

const WrapperStyled = styled(Stack)`
`;

export default ContactPage;
