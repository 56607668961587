import React from "react";

// COMPONENTS
import { Box, Stack, useTheme } from "@mui/material";
import Button from "components/formControls/Button";

// ICONS
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';

// UNTILS
import useRoute from "hooks/useRoute";
import { CourseLessonDto } from "models/classes/courseLesson.class";
import { useTranslation } from "react-i18next";
import { PageRouteName } from "globals/enums/routes.enum";

type Props = {
  lessonDetail: CourseLessonDto | null;
  setSelectedLesson: (section: CourseLessonDto) => void;
};

const LessonItem = React.memo(({ lessonDetail, setSelectedLesson }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { redirect } = useRoute();

  return (
    <Stack
      bgcolor={theme.palette.accent.dark}
      padding={{ xs: '1rem' }}
      borderRadius={2}
      flexDirection={{ xs: 'row' }}
      alignItems={{ xs: 'center' }}
      columnGap={{ xs: '1rem' }}
    >
      <Stack flexDirection={{ xs: 'column' }} flex={1} rowGap={{ xs: '.25rem' }}>
        <Stack flexDirection={{ xs: 'row' }} alignItems={{ xs: 'center' }}>
          <Box fontSize={{ xs: 12, md: 14 }} color={theme.palette.text.light}>{t('Name course')}</Box>
        </Stack>
        <Box fontSize={{ xs: 12, md: 14 }} color={theme.palette.text.dark} fontStyle="italic">
          {lessonDetail?.courseName}
        </Box>
      </Stack>
      <Stack flexDirection={{ xs: 'column' }} flex={1} rowGap={{ xs: '.25rem' }}>
        <Stack flexDirection={{ xs: 'row' }} alignItems={{ xs: 'center' }}>
          <Box fontSize={{ xs: 12, md: 14 }} color={theme.palette.text.light}>{t('Name lesson')}</Box>
        </Stack>
        <Box fontSize={{ xs: 12, md: 14 }} color={theme.palette.text.dark} fontStyle="italic">
          {lessonDetail?.name}
        </Box>
      </Stack>
      <Stack flexDirection={{ xs: 'column' }} flex={1} rowGap={{ xs: '.25rem' }}>
        <Stack flexDirection={{ xs: 'row' }} justifyContent={{ xs: 'center' }} alignItems={{ xs: 'center' }}>
          <Box fontSize={{ xs: 12, md: 14 }} color={theme.palette.text.light}>{t('Time')}</Box>
        </Stack>
        <Box textAlign="center" fontSize={{ xs: 12, md: 14 }} color={theme.palette.text.dark} fontStyle="italic">
          {lessonDetail?.time!}
        </Box>
      </Stack>
      <Stack flexDirection={{ xs: 'column' }} flex={1} rowGap={{ xs: '.25rem' }}>
        <Stack flexDirection={{ xs: 'row' }} justifyContent={{ xs: 'center' }} alignItems={{ xs: 'center' }}>
          <Box fontSize={{ xs: 12, md: 14 }} color={theme.palette.text.light}>{t('Video type')}</Box>
        </Stack>
        <Box textAlign="center" fontSize={{ xs: 12, md: 14 }} color={theme.palette.text.dark} fontStyle="italic">
          {lessonDetail?.videoType!}
        </Box>
      </Stack>
      <Stack
        flexDirection={{ xs: 'row' }}
        justifyContent={{ xs: 'center' }}
        alignItems={{ xs: 'center' }}
        flex={.4}
        rowGap={{ xs: '.25rem' }}
        columnGap={{ xs: '1rem' }}
      >
        <Button
          onClick={() => setSelectedLesson(lessonDetail!)}
          color="success"
          width="100%"
          style={{ fontWeight: 300, padding: '.5rem' }}
        >
          <DeleteIcon style={{ color: theme.palette.text.light, fontSize: 24 }} />
        </Button>
        <Button
          onClick={() => redirect(`${PageRouteName.ProfileLessonUpdatePage}/${lessonDetail?.id}`, {
            coursePersonalId: lessonDetail?.coursePersonalId
          })}
          color="primary"
          width="100%"
          style={{ fontWeight: 300, padding: '.5rem' }}
        >
          <EditIcon style={{ color: theme.palette.text.light, fontSize: 24 }} />
        </Button>
      </Stack>
    </Stack >
  );
});

export default LessonItem;
