import React, { useLayoutEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";

// HOOKS
import { useAuthContext } from "apis/authApis/authContext";
import useRoute from "hooks/useRoute";

// COMPONENTS
import { Box, Stack, useTheme } from "@mui/material";

// CONSTANTS
import { PageRouteName } from "globals/enums/routes.enum";

// ICONS
import { baseBlogURL } from "apis/configs/requestApis";
import BlogIcon from 'assets/ic-blog.png';
import CourseIcon from 'assets/ic-course.png';
import DashboardIcon from 'assets/ic-dashboard.png';
import SignInIcon from 'assets/ic-login.png';
import PowerIcon from 'assets/ic-power.png';
import UserIcon from 'assets/ic-user.png';
import CodeIcon from 'assets/ic-coding.png';

type MenuItem = {
  id: number;
  route: PageRouteName;
  name: string;
  image: string;
  alt: string;
  subMenu?: PageRouteName[];
};

const HeaderItem = React.memo(() => {
  // instances
  const { t } = useTranslation();
  const theme = useTheme();
  const { pathname, redirect } = useRoute();

  // hooks
  const { token } = useAuthContext();

  // state
  const [currentRoute, setCurrentRoute] = useState<PageRouteName>(PageRouteName.DashboardPage);

  // functions
  const handleRedirectPage = (route: PageRouteName) => {
    if (route === PageRouteName.BlogPage) {
      window.location.href = `${baseBlogURL}`;
      return;
    }
    setCurrentRoute(route);
    redirect(route);
  };

  // memos
  const MenuClientItems: MenuItem[] = useMemo(() => [
    {
      id: 1,
      route: PageRouteName.DashboardPage,
      name: 'Dashboard',
      image: DashboardIcon,
      alt: 'Trang chủ - Cùng Học Online'
    },
    {
      id: 2,
      route: PageRouteName.CourseStorePage,
      name: 'Course',
      image: CourseIcon,
      alt: 'Khoá học - Cùng Học Online',
      subMenu: [
        PageRouteName.CourseCategoryStorePage,
        PageRouteName.CourseViewPage,
      ],
    },
    {
      id: 3,
      route: PageRouteName.ProjectPage,
      name: 'Project',
      image: CodeIcon,
      alt: 'Dự án - Cùng Học Online',
      subMenu: [
        PageRouteName.ProjectDetailPage
      ]
    },
    {
      id: 4,
      route: PageRouteName.BlogPage,
      name: 'Blog',
      image: BlogIcon,
      alt: 'Tin tức - Cùng Học Online'
    },
    {
      id: 5,
      route: token ? PageRouteName.ProfilePage : PageRouteName.SignInPage,
      name: token ? 'Account' : 'Sign In',
      image: token ? UserIcon : SignInIcon,
      alt: 'Tài khoản - Cùng Học Online',
      subMenu: [
        PageRouteName.ProfileInformationPage,
        PageRouteName.ProfilePasswordPage,
        PageRouteName.ProfilePurchasedPage,
        PageRouteName.ProfileLessonPage,
        PageRouteName.ProfileSallerPage,
        PageRouteName.ProfileSectionPage,
        PageRouteName.ProfileDescriptionPage,
        PageRouteName.ProfileManageStudentPage,
        PageRouteName.ProfileUserPage,
        PageRouteName.ProfileCategoriesPage,
      ]
    },
  ], [token]);

  const MenuLogoutItem: MenuItem = useMemo(() => {
    return {
      id: Number(MenuClientItems.length) + 1,
      route: PageRouteName.LogoutPage,
      name: 'Log out',
      image: PowerIcon,
      alt: 'Đăng xuất'
    }
  }, [token]);

  // effects
  useLayoutEffect(() => {
    setCurrentRoute(pathname as PageRouteName);
  }, [pathname]);

  const renderMenuItem = (item: MenuItem) => (
    <Stack
      key={item.id}
      flexDirection={{ xs: 'row' }}
      columnGap={{ xs: '.75rem', md: '1rem' }}
      alignItems={{ xs: 'center' }}
      padding={{ xs: '.5rem 1rem', md: '.75rem 1.5rem', }}
      borderRadius={{ xs: '10px' }}
      style={{ cursor: 'pointer' }}
      className={item.route === currentRoute || (item.subMenu?.length && item.subMenu.some(item => currentRoute.includes(item))) ? 'bg-main' : ''}
      onClick={() => handleRedirectPage(item.route)}
    >
      <Box width={{ xs: '20px' }} height={{ xs: '20px' }}>
        <img src={item.image} width="100%" height="auto" alt={item.alt} />
      </Box>
      <Box
        className="menu-active"
        fontSize={{ xs: 14, md: 16 }}
        color={{ xs: theme.palette.text.light }}
        fontWeight={{ xs: '400' }}
      >
        {t(item.name)}
      </Box>
    </Stack>
  )

  return (
    <Stack
      pt={{ xs: '1rem', md: '4rem' }}
      flexDirection={{ xs: 'column' }}
      rowGap={{ xs: '.5rem', md: '1rem' }}
    >
      {MenuClientItems.map(item => renderMenuItem(item))}
      {token && renderMenuItem(MenuLogoutItem)}
    </Stack>
  );
});

export default HeaderItem;