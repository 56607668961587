import { CourseCategoryAllDto, CourseCategoryListDto } from "models/classes/courseCategory.class";
import { createContext, PropsWithChildren, useContext, useState } from "react";

type State = {
  courseCategoryList: CourseCategoryListDto[];
  courseCategoryAll: CourseCategoryAllDto[];
  categoryDetail: CourseCategoryAllDto | null;
};

type Actions = {
  updateCourseCategoryList: (data: CourseCategoryListDto[]) => void;
  updateCourseCategoryAll: (data: CourseCategoryAllDto[]) => void;
  updateCategoryDetail: (data: CourseCategoryAllDto) => void;
};

const initState: State = {
  courseCategoryList: [],
  courseCategoryAll: [],
  categoryDetail: null,
};

const defaultActions: Actions = {
  updateCourseCategoryList: () => { },
  updateCourseCategoryAll: () => { },
  updateCategoryDetail: () => { },
};

const CourseCategoryContext = createContext<State & Actions>({
  ...initState,
  ...defaultActions,
});

export const CourseCategoryProvider = ({ children }: PropsWithChildren<{}>) => {
  const [courseCategoryList, setCourseCategoryList] = useState<
    CourseCategoryListDto[]
  >([]);
  const [courseCategoryAll, setCourseCategoryAll] = useState<
    CourseCategoryAllDto[]
  >([]);
  const [categoryDetail, setCategoryDetail] = useState<CourseCategoryAllDto | null>(null);

  const updateCourseCategoryList = (data: CourseCategoryListDto[]) => {
    setCourseCategoryList(data);
  };

  const updateCourseCategoryAll = (data: CourseCategoryAllDto[]) => {
    setCourseCategoryAll(data);
  };

  const updateCategoryDetail = (data: CourseCategoryAllDto) => {
    setCategoryDetail(data);
  };

  return (
    <CourseCategoryContext.Provider
      value={{
        // Course Category List By Student
        courseCategoryList,
        updateCourseCategoryList,

        // Course Category All
        courseCategoryAll,
        updateCourseCategoryAll,

        // category detail
        categoryDetail,
        updateCategoryDetail,
      }}
    >
      {children}
    </CourseCategoryContext.Provider>
  );
};

export const useCourseCategoryContext = () => useContext(CourseCategoryContext);
