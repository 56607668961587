
// CONSTANTS
import { SessionKey, StorageKey } from 'globals/constants/storage';

// SERVICES
import {
  getSession,
  removeSession,
  removeStorage,
  setSession
} from 'services/storageService';

let accessToken: string | object;

// Load user info and access token to local variables to reduce JSON.parse session
const loadAuth = () => {
  const sessionAuthData = getSession([SessionKey.AccessToken]);

  if (sessionAuthData) {
    accessToken = sessionAuthData?.accessToken || '';
  }
};

export const setAuth = (authData: { accessToken: string }) => {
  setSession(SessionKey.AccessToken, authData);
  accessToken = authData.accessToken;
};

// If user is not logged in, access token will be empty
export const getAccessToken = () => {
  if (!accessToken) {
    loadAuth();
  }
  return accessToken;
};

export const logout = () => {
  removeSession([
    SessionKey.AccessToken,
  ]);
  removeStorage([
    StorageKey.AccessToken,
  ])
  window.location.href = '/';
};
