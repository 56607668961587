import { Suspense, lazy } from "react";
import styled from "styled-components";

// COMPONENTS
import { Stack } from "@mui/material";

// LAZY
const BannerDashboard = lazy(() => import("./sections/BannerDashboard"));
const CourseCategory = lazy(() => import("./sections/CourseCategory"));
const CourseFavorite = lazy(() => import("./sections/CourseFavorite"));
const BlogNew = lazy(() => import("./sections/BlogNew"));

const DashboardPage = () => {
  return (
    <WrapperStyled
      padding={{ xs: '3rem 2rem' }}
      width={{ xs: 'auto', md: '100%' }}
      flexDirection={{ xs: 'column' }}
      rowGap={{ xs: '3rem' }}
      position={{ xs: 'relative' }}
    >
      <Suspense>
        <BannerDashboard />
        <CourseCategory />
        <Stack
          width={{ xs: '-webkit-fill-available' }}
          flexDirection={{ xs: 'column', md: 'row' }}
          columnGap={{ xs: '3rem' }}
          rowGap={{ xs: '3rem' }}
        >
          <CourseFavorite />
          <BlogNew />
        </Stack>
      </Suspense>
    </WrapperStyled>
  )
};

const WrapperStyled = styled(Stack)`
`;

export default DashboardPage;
