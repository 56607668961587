import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

// HOOKS
import useRoute from "hooks/useRoute";
import { useSearchParams } from "react-router-dom";
import { useSnackbarContext } from "store/snackbarContext";

// COMPONENTS
import { Stack, useTheme } from "@mui/material";
import ProfileSearchNavbar from "./ProfileSearchNavbar";
import DescriptionItem from "../items/DescriptionItem";
import PaginationItem from "views/PaginationItem";
import DialogSlide from "components/modals/DialogSlide";

// CONSTANTS
import { PageRouteName } from "globals/enums/routes.enum";
import { CourseDescriptionByCourseDetailDto } from "models/classes/courseDescription.class";

// APIS
import { useCourseDescriptionListByApi } from "apis/courseDescriptionApis/useCourseDescriptionApis";
import { actionCourseDescriptionDeleteApi } from "apis/courseDescriptionApis/courseDescriptionAction";

const ProfileDescription = React.memo(() => {
  // instances
  const { t } = useTranslation();
  const theme = useTheme();
  const { redirect } = useRoute();
  const [searchParams] = useSearchParams();
  const searchName = searchParams.get('name') ? String(searchParams.get('name')) : '';
  const page = searchParams.get('page') ? Number(searchParams.get('page')) : 1;

  // states
  const [isDialogDelete, setIsDialogDelete] = useState(false);
  const [selectedDescription, setSelectedDescription] = useState<CourseDescriptionByCourseDetailDto | null>(null);

  // hooks
  const { updateSnackbar } = useSnackbarContext();
  const { courseDescriptionListRes, fetchCourseDescriptionListByApi } = useCourseDescriptionListByApi({
    searchName,
    page,
  });

  // functions
  const onSubmitSearch = (value: string) => {
    let queryParams = {};
    queryParams['page'] = 1;
    queryParams['name'] = value;
    redirect(PageRouteName.ProfileDescriptionPage, queryParams);
  };

  const handleDescDelete = async () => {
    try {
      setIsDialogDelete(false);
      const data = await actionCourseDescriptionDeleteApi({
        courseDescriptionId: selectedDescription?.id,
        coursePersonalId: selectedDescription?.coursePersonalId,
      });
      if (data) {
        updateSnackbar({ type: 'success', message: 'Delete success', open: true });
        fetchCourseDescriptionListByApi();
      } else {
        updateSnackbar({ type: 'error', message: 'Delete fail', open: true });
      }
    } catch (error) {
      console.log('handleDescDelete', error);
    }
  };

  return (
    <WrapperStyled
      padding={{ xs: '1rem', md: '1rem 2rem' }}
      bgcolor={theme.palette.default.dark}
      width={{ xs: '-webkit-fill-available' }}
      flexDirection="column"
      alignItems="center"
      borderRadius={3}
      rowGap={{ xs: '1.5rem' }}
    >
      <ProfileSearchNavbar
        searchName={searchName}
        onSubmitSearch={onSubmitSearch}
        labelButton="Create description"
        onClickButton={() => redirect(PageRouteName.ProfileDescriptionCreatePage)}
      />
      <Stack flexDirection="column" rowGap={{ xs: '1.5rem' }} width={{ xs: '100%' }}>
        {(courseDescriptionListRes?.list || [])?.map((item) => (
          <DescriptionItem
            key={item.id}
            descriptionDetail={item}
            setSelectedDescription={(
              description: CourseDescriptionByCourseDetailDto) => {
              setSelectedDescription(description);
              setIsDialogDelete(true);
            }}
          />
        ))}
      </Stack>
      <DialogSlide
        label="Information"
        open={isDialogDelete}
        onClose={(value) => setIsDialogDelete(value)}
        onConfirm={handleDescDelete}
      >
        <Stack
          flexDirection={{ xs: 'column' }}
          rowGap={{ xs: '1rem' }}
          fontWeight={300}
          color={theme.palette.text.light}
        >
          {t('Do you want to delete this section')}
        </Stack>
      </DialogSlide>
      <PaginationItem
        page={page}
        setPage={(page) => redirect(PageRouteName.ProfileDescriptionPage, { page, name: searchName })}
        totalPage={Math.ceil(Number(courseDescriptionListRes?.total) / Number(courseDescriptionListRes?.pageSize)) || 1}
      />
    </WrapperStyled>
  );
});

const WrapperStyled = styled(Stack)`

`;

export default ProfileDescription;
