import BaseModel from "./base.class";

export class CourseBuyerDto extends BaseModel<CourseBuyerDto> {
  id?: number;
  name?: string;
  description?: string;
  image?: string;
  author?: string;
}

export type CourseBuyerListByStudentRes = {
  list: CourseBuyerDto[];
  total: number;
  page: number;
  pageSize: number;
};

export class UserName extends BaseModel<UserName> {
  id?: number;
  name?: string;
  email?: string;
}

export class CoursePersonalName extends BaseModel<CoursePersonalName> {
  id?: number;
  name?: string;
  description?: string;
}

export class CourseBuyerListByAdminDto extends BaseModel<CourseBuyerListByAdminDto> {
  id?: number;
  user?: UserName;
  coursePersonal?: CoursePersonalName;
}

export type CourseBuyerListByAdminRes = {
  list: CourseBuyerListByAdminDto[];
  total: number;
  page: number;
  pageSize: number;
};

export type CourseBuyerData = {
  userId?: number;
  coursePersonalId?: number;
  email?: string;
  created_at?: string;
};
