import CheckIcon from '@mui/icons-material/Check';
import { Box, Stack, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';

type Props = {
  label: string;
  checked: boolean;
  onChange: (e: any) => void;
  onClickLabel?: () => void;
}

const Checkbox = ({
  label,
  checked = false,
  onChange,
  onClickLabel,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  return (
    <Stack
      flexDirection={{ xs: 'row' }}
      columnGap={{ xs: '1rem' }}
      alignItems={{ xs: 'center' }}
    >
      <Box
        sx={{
          border: `1px solid ${theme.palette.accent.main}`,
          background: theme.palette.primary.dark,
          width: '20px',
          height: '20px',
          borderRadius: '4px',
          cursor: 'pointer',
        }}
        onClick={() => onChange(!checked)}
      >
        {checked && <CheckIcon style={{ color: theme.palette.accent.main, fontSize: 20 }} />}
      </Box>
      <Box
        style={{ cursor: 'pointer' }}
        color={theme.palette.text.light}
        fontSize={14}
        onClick={onClickLabel && onClickLabel}
      >
        {t(label)}
      </Box>
    </Stack>

  )
};

export default Checkbox;