import BaseModel from "./base.class";

export class ProjectDetailDto extends BaseModel<ProjectDetailDto> {
  id?: number;
  name?: string;
  description?: string;
  content?: string;
  numberOfBuyers?: number;
  linkSrc?: string;
  image?: string;
  author?: string;
  price?: string;
  video?: string;
  highlight?: { id: number; name: string }[];
}

export type ProjectListRes = {
  list: ProjectDetailDto[];
  total: number;
  page: number;
  pageSize: number;
};
