import BaseModel from "./base.class";

export class CourseLessonByCourseDetailDto extends BaseModel<CourseLessonByCourseDetailDto> {
  id?: number;
  name?: string;
  time?: string;
}

export class CourseLessonInfoDto extends BaseModel<CourseLessonInfoDto> {
  id?: number;
  name?: string;
  content?: string;
  time?: string;
  videoUrl?: string;
  videoType?: string;
}

export class CourseLessonDto extends BaseModel<CourseLessonDto> {
  id?: number;
  name?: string;
  content?: string;
  time?: string;
  videoUrl?: string;
  videoType?: string;
  coursePersonalId?: number;
  courseSectionId?: number;
  courseName?: string;
  sectionName?: string;
}

export type CourseLessonListRes = {
  list: CourseLessonDto[];
  total: number;
  page: number;
  pageSize: number;
};

export type CourseLessonData = {
  name: string;
  content: string;
  time: string;
  videoUrl: string;
  videoType: string;
  coursePersonalId: number;
  courseSectionId: number;
};
