import { isEmpty } from "lodash";
import {
  AuthResponseSignIn,
  UserDto,
  UserInfoReq,
} from "models/classes/auth.class";
import {
  fetchAuthDetailProfileApi,
  fetchAuthSignInApi,
  fetchAuthSignUpApi,
  fetchAuthUpdateProfileApi,
  fetchResetPassowrdApi,
  fetchUpdateByAdminApi,
  fetchUserListResApi,
} from "./authFetch";
import { FetchAuthParams } from "./authParam";

export const actionAuthSignInApi = async ({ ...params }: FetchAuthParams) => {
  try {
    const { data } = await fetchAuthSignInApi(params);
    if (!isEmpty(data)) {
      return new AuthResponseSignIn().fromPayload(data);
    }
  } catch (error) {
    console.log(error);
  }
};

export const actionAuthSignUpApi = async ({ ...params }: FetchAuthParams) => {
  try {
    const data = await fetchAuthSignUpApi(params);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const actionAuthUpdateProfileApi = async ({
  ...params
}: FetchAuthParams) => {
  try {
    const { data } = await fetchAuthUpdateProfileApi(params);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const actionAuthUpdatePasswordApi = async ({
  ...params
}: FetchAuthParams) => {
  try {
    const { data } = await fetchAuthUpdateProfileApi(params);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const actionAuthDetailProfileApi = async ({
  ...params
}: FetchAuthParams) => {
  try {
    const { data } = await fetchAuthDetailProfileApi(params);
    if (!isEmpty(data)) {
      return new UserDto().fromPayload(data);
    }
  } catch (error) {
    console.log(error);
  }
};

export const actionUserListResApi = async ({ ...params }: FetchAuthParams) => {
  try {
    const { data } = await fetchUserListResApi(params);
    if (!isEmpty(data)) {
      return {
        list: data.list.map((item: UserDto) => new UserDto().fromPayload(item)),
        page: data.page,
        pageSize: data.pageSize,
        total: data.total,
      };
    }
  } catch (error) {
    console.log(error);
  }
};

export const actionResetPasswordByAdminApi = async (body: UserInfoReq) => {
  try {
    const { data } = await fetchResetPassowrdApi(body);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const actionUpdateByAdminApi = async (body: UserInfoReq) => {
  try {
    const { data } = await fetchUpdateByAdminApi(body);
    return data;
  } catch (error) {
    console.log(error);
  }
};
