import { Suspense, lazy, useMemo } from "react";
import styled from "styled-components";

// ICONS
import CharacterBanner from 'assets/banner-khoahoc.webp';

// COMPONENTS
import { Stack } from "@mui/material";
import { PROJECTS } from "globals/enums/projects.enum";
import useRoute from "hooks/useRoute";
import { ProjectDetailDto } from "models/classes/project.class";
const BannerLayout = lazy(() => import("layouts/BannerLayout"));
const ProjectContent = lazy(() => import("./sections/ProjectContent"));
const ProjectBody = lazy(() => import("./sections/ProjectBody"));

const ProjectDetailPage = () => {
  // instances
  const { params: { id: projectId } } = useRoute();

  const projectDetail = useMemo<ProjectDetailDto | null>(() => {
    return new ProjectDetailDto().fromPayload(PROJECTS.find(item => item.id == projectId) as ProjectDetailDto);
  }, [projectId]);

  return (
    <WrapperStyled
      padding={{ xs: '3rem 2rem' }}
      width={{ xs: 'auto', md: '100%' }}
      flexDirection={{ xs: 'column' }}
      rowGap={{ xs: '3rem' }}
    >
      <Suspense>
        <BannerLayout
          height={{ xs: '10rem', md: '12rem' }}
          title="Project detail"
          subTitle="Choose your favorite project!"
          imageFile={CharacterBanner}
          widthImage={{ xs: '150px', md: '200px' }}
          heightImage={{ xs: '150px' }}
        />
        <ProjectContent projectDetail={projectDetail!} />
        {projectDetail?.price && (
          <ProjectBody projectDetail={projectDetail!} />
        )}
      </Suspense>
    </WrapperStyled>
  );
};

const WrapperStyled = styled(Stack)`
`;


export default ProjectDetailPage;
