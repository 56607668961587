import React, { useLayoutEffect } from "react";
import ReactDOM from "react-dom/client";
import reportWebVitals from './reportWebVitals';

// CSS & COMPONENTS
import App from './App';
import './index.css';

// REDUX

// MUI
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { AuthProvider } from "apis/authApis/authContext";
import { CourseBuyerProvider } from "apis/courseBuyerApis/courseBuyerContext";
import { CourseCategoryProvider } from "apis/courseCategoryApis/courseCategoryContext";
import { CourseDescriptionProvider } from "apis/courseDescriptionApis/courseDescriptionContext";
import { CourseLessonProvider } from "apis/courseLessonApis/courseLessonContext";
import { CoursePersonalProvider } from "apis/coursePersonalApis/coursePersonalContext";
import { CourseSectionProvider } from "apis/courseSectionApis/courseSectionContext";
import { RouteProvider } from "store/routeContext";
import { SnackbarProvider } from "store/snackbarContext";
import { ThemeProvider } from 'styled-components';
import { colorDark } from "theme/mode";
import customTheme from 'theme/theme';
import SnackbarItem from "views/SnackbarItem";

function MyApp() {
  const theme = customTheme(colorDark);

  useLayoutEffect(() => {
    // Slide
    const linkSlider: HTMLLinkElement = document.createElement("link");
    linkSlider.rel = 'stylesheet';
    linkSlider.type = 'text/css';
    linkSlider.href = 'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick.min.css';
    document.head.appendChild(linkSlider);

    // Slide Theme
    const linkSliderTheme: HTMLLinkElement = document.createElement("link");
    linkSliderTheme.rel = 'stylesheet';
    linkSliderTheme.type = 'text/css';
    linkSliderTheme.href = 'https://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.6.0/slick-theme.min.css';
    document.head.appendChild(linkSliderTheme);
  }, []);

  return (
    <MuiThemeProvider theme={theme}>
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <RouteProvider>
            <SnackbarProvider>
              <CoursePersonalProvider>
                <CourseCategoryProvider>
                  <CourseLessonProvider>
                    <CourseBuyerProvider>
                      <CourseSectionProvider>
                        <CourseDescriptionProvider>
                          <App />
                          <SnackbarItem />
                        </CourseDescriptionProvider>
                      </CourseSectionProvider>
                    </CourseBuyerProvider>
                  </CourseLessonProvider>
                </CourseCategoryProvider>
              </CoursePersonalProvider>
            </SnackbarProvider>
          </RouteProvider>
        </AuthProvider>
      </ThemeProvider>
    </MuiThemeProvider>
  )
}

const root = ReactDOM.createRoot(document.getElementById("root")!);
root.render(
  <React.Fragment>
    <MyApp />
  </React.Fragment>
);

reportWebVitals();