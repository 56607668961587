import React from "react";
import styled from "styled-components";

// HOOKS

// COMPONENTS
import { Stack, useTheme } from "@mui/material";

// ICONS
import CharacterBanner from 'assets/banner-khoahoc.webp';
import BannerLayout from "layouts/BannerLayout";

type Props = {
  linkItemRender: React.ReactNode;
}

const ProfileNavbar = React.memo(({
  linkItemRender
}: Props) => {
  const theme = useTheme();

  return (
    <Stack
      flexDirection={{ xs: 'column' }}
      rowGap={{ xs: '1rem' }}
    >
      <BannerLayout
        height={{ xs: '10rem', md: '12rem' }}
        title="Personal information"
        subTitle="Change your personal information as you want!"
        imageFile={CharacterBanner}
        widthImage={{ xs: '150px', md: '200px' }}
        heightImage={{ xs: '150px' }}
      />
      <WrapperStyled
        padding={{ xs: '1rem', md: '1rem 2rem' }}
        bgcolor={theme.palette.default.dark}
        borderRadius={3}
      >
        <Stack
          flexDirection={{ xs: 'row' }}
          columnGap={{ xs: '1rem', md: '2rem' }}
          alignItems={{ xs: 'center' }}
        >
          <Stack
            flexDirection={{ xs: 'row' }}
            rowGap={{ xs: '1rem', md: '2rem' }}
            width="100%"
          >
            <Stack
              flexDirection={{ xs: 'row' }}
              columnGap={{ xs: '.5rem', md: '1rem' }}
              flexWrap={{ xs: 'wrap' }}
              rowGap={{ xs: '1rem' }}
            >
              {linkItemRender}
            </Stack>
          </Stack>
        </Stack>
      </WrapperStyled>
    </Stack>
  );
});

const WrapperStyled = styled(Stack)`
  .link-item {
    color: ${p => p.theme.palette.text.light};
    font-weight: 300;
    cursor: pointer;
    border-radius: 4px;
  }
`;

export default ProfileNavbar;
