import { StorageKey } from 'globals/constants/storage';
import { Obj } from 'globals/constants/types';
import i18n from 'i18next';
import { isEmpty } from 'lodash';
import { logout } from 'services/authService';
import { getStorage } from 'services/storageService';
import { Interceptor } from 'utils/requestHelpers';

declare module 'axios' {
  interface AxiosRequestConfig {
    options?: {
      notifySuccess?: boolean;
      useFirebaseToken?: boolean;
      newAbortSignal?: () => AbortSignal;
    };
  }
}

export const dataInterceptor: Interceptor = {
  onRequest: config => {
    config.headers!['Accept-Language'] = i18n.language;
    return config;
  },
  onResponse: ({ data }) => data,
}


export const abortInterceptor: Interceptor = {
  onRequest: config => {
    if (config?.options?.newAbortSignal) {
      config.signal = config?.options?.newAbortSignal();
    }
    return config;
  },
  onResponseError: error => {
    if (error.code === 'ERR_CANCELED') {
      console.error('Cancelled request', error);
      return Promise.reject('Request was aborted!');
    }
    
    return Promise.reject(error);
  }
};

export const notifyInterceptor: Interceptor = {
  onResponseError: error => {
    // timeout, request cancelled
    if (error.code === 'ECONNABORTED') {
      console.log(error);
    } else if (error?.response?.data) {
      // @ts-ignore
      const { data: { errors, message }, status } = error.response;
      
      if (status === 500) {
        console.log(error);
      }
      else if (!isEmpty(errors)) {
        errors.forEach((e: { message: string }) => {
          if (e.message) {
            console.log(error);
          }
        });
      } else if (message) {
        console.log(error);
      }
    }

    return Promise.reject(error);
  }
};

export const customerInterceptor: Interceptor = {
  onRequest: (config: Obj) => {
    const authData = getStorage(StorageKey.AccessToken) || '';
    if (authData) {
      config.headers['Authorization'] = `Bearer ${authData}`;
    }
    
    return config;
  },
  onResponseError: error => {
    if (error.response?.status === 401) {
      logout();
    }
    
    return Promise.reject(error);
  },
};
