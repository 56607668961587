import React from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

// ICONS

// COMPONENTS
import { Box, Stack, useTheme } from "@mui/material";
import { CoursePersonalDetailDto } from "models/classes/coursePersonal.class";
import { isEmpty } from "lodash";

type Props = {
  coursePersonalDetail: CoursePersonalDetailDto | null;
};

const TeacherContact = React.memo(({ coursePersonalDetail }: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();

  return (
    <WrapperStyled
      width={{ xs: '-webkit-fill-available' }}
      height={{ xs: 150 }}
      flexDirection="column"
      justifyContent="center"
      bgcolor={theme.palette.default.dark}
      rowGap={{ xs: '1rem' }}
      padding={{ xs: '2rem' }}
      borderRadius={3}
    >
      {isEmpty(coursePersonalDetail) && (
        <Box>{t('No data')}</Box>
      )}
      {!isEmpty(coursePersonalDetail) && (
        <>
          <Box
            fontSize={{ xs: '20px' }}
            fontWeight={{ xs: '500' }}
            textTransform={{ xs: 'uppercase' }}
            color={theme.palette.text.light}
          >
            {t('Teacher information')} 👇
          </Box>
          <Box fontSize={{ xs: 14, md: 16 }} color={theme.palette.text.light}>
            ✅ {t('Teacher')}: {coursePersonalDetail.user?.name || ''}
          </Box>
          <Box fontSize={{ xs: 14, md: 16 }} color={theme.palette.text.light}>
            ✅ {t('Zalo')}: {coursePersonalDetail.user?.zaloContact || ''}
          </Box>
          <Box fontSize={{ xs: 14, md: 16 }} color={theme.palette.text.light}>
            ✅ {t('Social')}: <a
              href={coursePersonalDetail.user?.socialLink || 'https://www.facebook.com/nkt.9920/'}
              target="_blank"
              style={{ color: theme.palette.text.light, textDecoration: 'none' }}
            >
              👉 {t('View now')}
            </a>
          </Box>
        </>
      )}
    </WrapperStyled>
  );
});

const WrapperStyled = styled(Stack)`

`;

export default TeacherContact;
