import styled from "styled-components";
import useRoute from "hooks/useRoute";

// COMPONENTS
import { Box, Stack, useTheme } from "@mui/material";

// ICONS
import NinedevLogo from 'assets/ninedev-icon.png';
import React from "react";
import HeaderItem from "./sections/HeaderItem";
import HeaderLanguage from "./sections/HeaderLanguage";
import { PageRouteName } from "globals/enums/routes.enum";

const HeaderLayout = React.memo(() => {
  const theme = useTheme();
  const { redirect } = useRoute();

  return (
    <WrapperStyled
      bgcolor={{ xs: theme.palette.default.dark }}
      height={{ xs: '92.5%', md: '-webkit-fill-available' }}
      position={{ xs: 'fixed' }}
      overflow={{ xs: 'auto', md: 'auto' }}
      boxShadow={{ xs: '1px 1px 10px rgba(0, 0, 0, 0.15)' }}
      padding={{ xs: '1rem 1rem', md: '2rem' }}
      width={{ xs: '25%', md: 'auto' }}
      zIndex={1300}
    >
      <Stack
        flexDirection={{ xs: 'row' }}
        alignItems={{ xs: 'center' }}
        justifyContent={{ xs: 'center' }}
        columnGap={{ xs: '4px' }}
        onClick={() => redirect(PageRouteName.DashboardPage)}
        style={{ cursor: 'pointer' }}
      >
        <Box width={{ xs: '60px' }} height={{ xs: '60px' }}>
          <img src={NinedevLogo} width="100%" height="auto" alt="Ninedev học online" />
        </Box>
        <Box
          fontSize={{ xs: 26 }}
          color={theme.palette.accent.main}
          fontWeight={{ xs: '600' }}
          letterSpacing={{ xs: '1px' }}
          textTransform={{ xs: 'uppercase' }}
          display={{ xs: 'none', md: 'block' }}
        >
          Ninedev
        </Box>
      </Stack>

      <Stack flexDirection="column" justifyContent="space-between" height="100%">
        <HeaderItem />
        <HeaderLanguage />
      </Stack>
    </WrapperStyled>
  )
});

const WrapperStyled = styled(Stack)`

`;

export default HeaderLayout;