import { isEmpty } from "lodash";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

// HOOKS
import { useCourseCategoryAllByApi } from "apis/courseCategoryApis/useCourseCategoryApis";
import { useCourseDetailPublicByApi } from "apis/coursePersonalApis/useCoursePersonalApis";
import useRoute from "hooks/useRoute";
import { useSnackbarContext } from "store/snackbarContext";

// FORMS
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from 'react-hook-form';
import * as yup from "yup";

// COMPONENTS
import { Box, Stack, useTheme } from "@mui/material";
import Button from "components/formControls/Button";
import Checkbox from "components/formControls/Checkbox";
import SelectField from "components/formControls/Select";
import TextField from "components/formHooks/TextField";
import DialogSlide from "components/modals/DialogSlide";

// ICONS
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';

// APIS
import { actionCoursePersonalCreateApi, actionCoursePersonalUpdateApi } from "apis/coursePersonalApis/coursePersonalAction";
import { fetchCoursePersonalUploadApi } from "apis/coursePersonalApis/coursePersonalFetch";

// CONSTANTS
import { baseImageURL } from "apis/configs/requestApis";
import { SelectOption } from "globals/constants/types";
import { CoursePersonalStatus, IsCreate, IsEdit, PricingOptions } from "globals/enums/courses.enum";
import { CoursePersonalData } from "models/classes/coursePersonal.class";
import { validationFile, validationSize } from "utils/dataHelpers";

const ProfileSallerCreate = React.memo(() => {
  // instance
  const { t } = useTranslation();
  const theme = useTheme();
  const { goBack, params } = useRoute();
  const isView = params.id ? IsEdit : IsCreate;

  // states
  const [courseCategoryId, setCourseCategoryId] = useState(-1);
  const [pricing, setPricing] = useState(-1);
  const [isCommitment, setIsCommitment] = useState(false);
  const [isDialogCommit, setIsDialogCommit] = useState(false);

  // refs
  const imageRef = useRef<HTMLInputElement>(null);

  // forms
  const sallerSchema = yup.object({
    name: yup.string().required(t('This value cannot be blank')).nullable(),
    description: yup.string().required(t('This value cannot be blank')).nullable(),
    content: yup.string().required(t('This value cannot be blank')).nullable(),
    price: yup.number().required(t('This value cannot be blank')).min(0).max(10000000).nullable(),
    image: yup.string().required(t('This value cannot be blank')).nullable(),
  });

  const { control, handleSubmit, setValue, reset } = useForm<any>({
    resolver: yupResolver(sallerSchema),
  });

  // hooks
  const { coursePersonalDetail } = useCourseDetailPublicByApi(params.id);
  const { courseCategoryAll } = useCourseCategoryAllByApi();
  const { updateSnackbar } = useSnackbarContext();

  // functions
  const handleUpload = async (file: any) => {
    if (!isEmpty(file)) {
      const newFile = file[0];
      if (validationSize(newFile)) {
        if (validationFile(newFile)) {
          const { filename } = await fetchCoursePersonalUploadApi(newFile) as any;
          if (filename) {
            setValue('image', `${baseImageURL}/${filename}`);
          } else {
            updateSnackbar({ type: 'error', message: 'Server internal error', open: true });
          }
        } else {
          updateSnackbar({ type: 'error', message: 'Uploaded data must be an image', open: true });
        }
      } else {
        updateSnackbar({ type: 'error', message: 'Images must be less than 1MB', open: true });
      }
    }
    if (imageRef.current) {
      imageRef.current.value = "";
    }
  };

  const handleCourseCreate = async (values: CoursePersonalData) => {
    try {
      if (courseCategoryId === -1) {
        updateSnackbar({ type: 'error', message: 'Select course type', open: true });
        return;
      } else if (pricing === -1) {
        updateSnackbar({ type: 'error', message: 'Select course pricing', open: true });
        return;
      }
      values = {
        ...values,
        courseCategoryId,
        isPublic: pricing === 1 ? true : false,
      };
      const data = await actionCoursePersonalCreateApi(values);
      if (data) {
        updateSnackbar({ type: 'success', message: 'Create success', open: true });
        goBack();
      } else {
        updateSnackbar({ type: 'error', message: 'Create fail', open: true });
      }
    } catch (error) {
      console.log('handleCourseCreate', error);
    }
  };

  const handleCourseUpdate = async (values: CoursePersonalData) => {
    try {
      if (courseCategoryId === -1) {
        updateSnackbar({ type: 'error', message: 'Select course type', open: true });
        return;
      } else if (pricing === -1) {
        updateSnackbar({ type: 'error', message: 'Select course pricing', open: true });
        return;
      }
      values = {
        ...values,
        courseCategoryId,
        isPublic: pricing === 1 ? true : false,
      };
      const data = await actionCoursePersonalUpdateApi(coursePersonalDetail?.id!, values);
      if (data) {
        updateSnackbar({ type: 'success', message: 'Update success', open: true });
        goBack();
      } else {
        updateSnackbar({ type: 'error', message: 'Update fail', open: true });
      }
    } catch (error) {
      console.log('handleCourseCreate', error);
    }
  };

  const handleCommitment = () => {
    setIsCommitment(true);
    setIsDialogCommit(false);
  }

  // effects
  useEffect(() => {
    if (isView === IsEdit) {
      reset({
        name: coursePersonalDetail?.name,
        description: coursePersonalDetail?.description,
        price: coursePersonalDetail?.price,
        image: coursePersonalDetail?.image,
        content: coursePersonalDetail?.content,
      });
      setCourseCategoryId(coursePersonalDetail?.courseCategoryId!);
      setPricing(coursePersonalDetail?.isPublic! ? 1 : 2);
      setIsCommitment(true);
    }
  }, [coursePersonalDetail]);

  return (
    <WrapperStyled
      padding={{ xs: '2rem' }}
      bgcolor={theme.palette.default.dark}
      width={{ xs: '-webkit-fill-available' }}
      flexDirection={{ xs: 'column' }}
      rowGap={{ xs: '1.5rem' }}
      borderRadius={3}
    >
      <Stack flexDirection={{ xs: 'row' }} justifyContent={{ xs: 'space-between' }} mb={{ xs: '1rem' }}>
        <Box
          fontSize={{ xs: 24, md: 30 }}
          color={theme.palette.text.light}
        >
          {t('Register to sell')}
        </Box>
        <Button
          disabled={!isCommitment}
          color="primary"
          style={{ fontWeight: 300 }}
          onClick={isView === IsEdit ? handleSubmit(handleCourseUpdate) : handleSubmit(handleCourseCreate)}
        >
          {t('Confirm')}
        </Button>
      </Stack>
      <Stack flexDirection={{ xs: 'row' }} columnGap={{ xs: '2rem' }} width={{ xs: '100%' }}>
        <Stack flex={1} rowGap={{ xs: '.5rem' }}>
          <Box color={theme.palette.text.light}>{t('Course category type')}</Box>
          <SelectField
            width="100%"
            placeHolder={t('Select course type')}
            selectedValue={courseCategoryId}
            options={courseCategoryAll.map(item => ({ label: item.name, value: item.id })) as SelectOption[]}
            onChange={(selectedValue) => setCourseCategoryId(Number(selectedValue))}
          />
        </Stack>
        <Stack flex={1} rowGap={{ xs: '.5rem' }}>
          <Box color={theme.palette.text.light}>{t('Name course')}</Box>
          <TextField
            control={control}
            name="name"
            placeholder={t('Enter your name course')}
            background={theme.palette.default.light}
            style={{ width: '100%' }}
            required
          />
        </Stack>
      </Stack>
      <Stack flexDirection={{ xs: 'row' }} columnGap={{ xs: '2rem' }} width={{ xs: '100%' }}>
        <Stack flex={1} rowGap={{ xs: '.5rem' }}>
          <Box color={theme.palette.text.light}>{t('Description sub')}</Box>
          <TextField
            control={control}
            maxLength={20}
            name="description"
            placeholder={t('Enter your description sub')}
            background={theme.palette.default.light}
            style={{ width: '100%' }}
            required
          />
        </Stack>
        <Stack flex={1} rowGap={{ xs: '.5rem' }}>
          <Box color={theme.palette.text.light}>{t('Price')}</Box>
          <TextField
            control={control}
            typeInput="number"
            name="price"
            placeholder={t('Enter your price here')}
            background={theme.palette.default.light}
            style={{ width: '100%' }}
            required
            InputProps={{
              readOnly: (!coursePersonalDetail?.isPublic && coursePersonalDetail?.status === CoursePersonalStatus.ONSALE)
                ? true : false
            }}
          />
        </Stack>
      </Stack>
      <Stack flexDirection={{ xs: 'row' }} columnGap={{ xs: '2rem' }} width={{ xs: '100%' }}>
        <Stack flex={1} rowGap={{ xs: '.5rem' }}>
          <Box color={theme.palette.text.light}>{t('Image')}</Box>
          <Stack flexDirection={{ xs: 'row' }} columnGap={{ xs: '1rem' }}>
            <Box width="100%">
              <TextField
                control={control}
                maxLength={250}
                name="image"
                placeholder={t('Enter your image here')}
                background={theme.palette.default.light}
                style={{ width: '100%' }}
                InputProps={{
                  readOnly: true,
                }}
                required
              />
              <input onChange={(e) => handleUpload(e.target.files)} ref={imageRef} type="file" style={{ display: 'none' }} />
            </Box>
            <Button color="primary" onClick={() => imageRef.current?.click()}>
              <AddPhotoAlternateIcon style={{ color: theme.palette.text.light }} />
            </Button>
          </Stack>
        </Stack>
        <Stack flex={1} rowGap={{ xs: '.5rem' }}>
          <Box color={theme.palette.text.light}>{t('Pricing')}</Box>
          <SelectField
            width="100%"
            placeHolder={t('Select course pricing')}
            selectedValue={pricing}
            options={PricingOptions.map(item => ({ label: item.label, value: item.value })) as SelectOption[]}
            onChange={(selectedValue) => setPricing(Number(selectedValue))}
            disabled={coursePersonalDetail?.status === CoursePersonalStatus.ONSALE ? true : false}
          />
        </Stack>
      </Stack>
      <Stack flexDirection={{ xs: 'row' }} columnGap={{ xs: '2rem' }} width={{ xs: '100%' }}>
        <Stack flex={1} rowGap={{ xs: '.5rem' }}>
          <Box color={theme.palette.text.light}>{t('Content')}</Box>
          <TextField
            multiline
            maxLength={250}
            control={control}
            name="content"
            placeholder={t('Enter your content here')}
            background={theme.palette.default.light}
            style={{ width: '100%' }}
            required
          />
        </Stack>
      </Stack>
      <Checkbox
        label="See the course building terms"
        checked={isCommitment}
        onChange={(checked) => setIsCommitment(checked)}
        onClickLabel={() => setIsDialogCommit(true)}
      />
      <DialogSlide
        label="The course building terms"
        open={isDialogCommit}
        onClose={(value) => setIsDialogCommit(value)}
        onConfirm={handleCommitment}
      >
        <Stack
          flexDirection={{ xs: 'column' }}
          rowGap={{ xs: '1rem' }}
          fontWeight={300}
          color={theme.palette.text.light}
        >
          <ul style={{ display: 'flex', flexDirection: 'column', paddingLeft: '1rem', rowGap: '1rem', fontSize: 14 }}>
            <li><strong>Tặng ngay 1 tháng đầu tiên miễn phí</strong>: Khi bắt đầu tạo khóa học, giảng viên sẽ được tặng ngay 1 tháng đầu tiên miễn phí để dạy học. Đây là cơ hội tuyệt vời để bạn thử nghiệm và phát triển khóa học của mình mà không cần lo lắng về chi phí.</li>
            <li><strong>Phí duy trì hàng tháng</strong>: Phí duy trì khóa học hàng tháng sẽ bằng giá trị của khóa học. Điều này giúp bạn dễ dàng dự toán chi phí và tối ưu hóa lợi nhuận từ việc giảng dạy.</li>
            <li><strong>Miễn phí duy trì cho khóa học có định giá miễn phí</strong>: Đối với các khóa học được định giá miễn phí, giảng viên sẽ không phải nộp phí duy trì hàng tháng. Đây là cơ hội để bạn chia sẻ kiến thức mà không phải lo lắng về chi phí.</li>
            <li><strong>Phí hoa hồng 10%:</strong> Chúng tôi áp dụng mức phí hoa hồng 10% cho mỗi học viên đăng ký. Phí hoa hồng này sẽ được cộng dồn cho tới khi có đủ 10 học viên và sẽ thu một lần. Điều này giúp bạn dễ dàng quản lý tài chính và thu nhập từ khóa học.</li>
            <li><strong>Khoá học có trạng thái hoạt động:</strong> Học viên có thể truy cập vào để xem khóa học.</li>
            <li><strong>Khoá học có trạng thái không hoạt động:</strong> Học viên không thể truy cập vào để xem khóa học.</li>
            <li><strong>Thay đổi giá khóa học:</strong> Khi khóa học đang ở trạng thái Hoạt động, giảng viên không thể thay đổi giá. Nếu muốn thay đổi giá, giảng viên phải chờ đến khi khóa học hết hạn. Giảng viên có thể gia hạn lại khóa học hoặc liên hệ với admin để gia hạn trước khi khóa học hết hạn.</li>
            <li><strong>Liên hệ với admin:</strong> Giảng viên có thể liên hệ với admin để kích hoạt trạng thái và gia hạn khóa học. Chúng tôi luôn sẵn sàng hỗ trợ bạn trong mọi tình huống để đảm bảo quá trình giảng dạy diễn ra suôn sẻ.</li>
            <li><strong>Xử phạt hành vi không trung thực:</strong> Mọi hành vi không trung thực sẽ bị xử phạt nặng. Chúng tôi cam kết xây dựng một môi trường học tập và giảng dạy minh bạch, công bằng và chuyên nghiệp.</li>
          </ul>
        </Stack>
      </DialogSlide>
    </WrapperStyled>
  );
});

const WrapperStyled = styled(Stack)`

`;

export default ProfileSallerCreate;
