import useAbortRequest from "hooks/useAbortRequest";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import {
  actionCourseSectionAllApi,
  actionCourseSectionDetailApi,
  actionCourseSectionsApi,
} from "./courseSectionAction";
import { useCourseSectionContext } from "./courseSectionContext";
import { FetchCourseSectionParams } from "./courseSectionParam";

export const useCourseSectionsByApi = ({
  coursePersonalId,
  searchName,
  page,
}: FetchCourseSectionParams) => {
  const { newAbortSignal, abortRequest } = useAbortRequest();

  const { courseSectionListRes, updateSectionList } = useCourseSectionContext();

  const fetchCourseSectionsByApi = async () => {
    await abortRequest();
    const data = await actionCourseSectionsApi({
      coursePersonalId,
      searchName,
      page,
      newAbortSignal,
    });
    if (!isEmpty(data)) {
      updateSectionList(data!);
    }
  };

  // Unmout Apis
  useEffect(() => {
    if (
      isEmpty(courseSectionListRes) ||
      coursePersonalId ||
      searchName ||
      page
    ) {
      fetchCourseSectionsByApi();
    }
    return () => {
      (async () => {
        await abortRequest();
      })();
    };
  }, [coursePersonalId, searchName, page]);

  return {
    courseSectionListRes,
    fetchCourseSectionsByApi,
  };
};

export const useCourseSectionAllByApi = ({
  coursePersonalId,
}: FetchCourseSectionParams) => {
  const { newAbortSignal, abortRequest } = useAbortRequest();

  const { sectionAll, updateSectionAll } = useCourseSectionContext();

  const fetchCourseSectionAllByApi = async () => {
    await abortRequest();
    const data = await actionCourseSectionAllApi({
      coursePersonalId,
      newAbortSignal,
    });
    updateSectionAll(data || []);
  };

  // Unmout Apis
  useEffect(() => {
    if (coursePersonalId) {
      fetchCourseSectionAllByApi();
    }
    return () => {
      (async () => {
        await abortRequest();
      })();
    };
  }, [coursePersonalId]);

  return {
    sectionAll,
    fetchCourseSectionAllByApi,
  };
};

export const useCourseSectionDetailByApi = ({
  coursePersonalId,
  courseSectionId,
}: FetchCourseSectionParams) => {
  const { newAbortSignal, abortRequest } = useAbortRequest();

  const { section, updateSection } = useCourseSectionContext();

  const fetchCourseSectionDetailByApi = async () => {
    await abortRequest();
    const data = await actionCourseSectionDetailApi({
      coursePersonalId,
      courseSectionId,
      newAbortSignal,
    });
    if (!isEmpty(data)) {
      updateSection(data!);
    }
  };

  // Unmout Apis
  useEffect(() => {
    if (coursePersonalId && courseSectionId) {
      fetchCourseSectionDetailByApi();
    }

    return () => {
      (async () => {
        await abortRequest();
      })();
    };
  }, [courseSectionId]);

  return {
    section,
    fetchCourseSectionDetailByApi,
  };
};
