import { Stack } from "@mui/material";
import CircularProgress from '@mui/material/CircularProgress';
import React from "react";

type Props = {
  src: string;
  width?: string | number;
  height?: string | number;
  bgLoading?: string;
  alt?: string;
  style?: object;
}

const LoadingImage = React.memo(({
  src,
  width = '100%',
  height = '100%',
  bgLoading = '#141625',
  alt = "Ninedev - Nền tảng học trực tuyến",
  style = {},
}: Props) => {
  return (
    <>
      {src ? (
        <img src={src} width={width} height={height} alt={alt} style={style} />
      ) : (
        <Stack
          bgcolor={bgLoading}
          width={width}
          height={height}
          flexDirection="row"
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress size="1rem" style={{ color: '#fff' }} />
        </Stack>
      )}
    </>
  )
});

export default LoadingImage;