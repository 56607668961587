import { Suspense, lazy } from "react";
import styled from "styled-components";

// ICONS
import CharacterBanner from 'assets/banner-khoahoc.webp';

// HOOKS
import { useCourseCategoryListByApi } from "apis/courseCategoryApis/useCourseCategoryApis";
import { useCoursePersonalClientListByApi } from "apis/coursePersonalApis/useCoursePersonalApis";
import useRoute from "hooks/useRoute";
import { useSearchParams } from "react-router-dom";

// CONSTANTS
import { PageRouteName } from "globals/enums/routes.enum";
import { CoursePersonalSearch } from "globals/types/course.type";

// COMPONENTS
import { Stack } from "@mui/material";

// LAZY
const BannerLayout = lazy(() => import("layouts/BannerLayout"));
const CourseCategoryList = lazy(() => import("./sections/CourseCategoryList"));
const CourseCategorySearch = lazy(() => import("./sections/CourseCategorySearch"));

const CourseCategoryPage = () => {
  // instances
  const { redirect } = useRoute();
  const [searchParams] = useSearchParams();
  const searchName = searchParams.get('name') ? String(searchParams.get('name')) : '';
  const page = searchParams.get('page') ? Number(searchParams.get('page')) : 1;
  const courseCategoryId = searchParams.get('courseCategoryId') ? Number(searchParams.get('courseCategoryId')) : 0;

  // hooks
  const { coursePersonalClientListRes } = useCoursePersonalClientListByApi({
    searchName,
    page,
    courseCategoryId
  });
  const { courseCategoryList } = useCourseCategoryListByApi();

  return (
    <WrapperStyled
      padding={{ xs: '3rem 2rem' }}
      width={{ xs: 'auto', md: '100%' }}
      flexDirection={{ xs: 'column' }}
      rowGap={{ xs: '2rem' }}
    >
      <Suspense>
        <BannerLayout
          height={{ xs: '10rem', md: '12rem' }}
          title="Course"
          subTitle="Choose your favorite course!"
          imageFile={CharacterBanner}
          widthImage={{ xs: '150px', md: '200px' }}
          heightImage={{ xs: '150px' }}
        />
        <CourseCategorySearch
          title="Search course"
          courseCategoryList={courseCategoryList}
          selectedCategory={courseCategoryId!}
          searchName={searchName}
          onSubmitSearch={(data: CoursePersonalSearch) =>
            redirect(PageRouteName.CourseCategoryStorePage, { page: 1, name: data.searchName, courseCategoryId: data.selectedValue })
          }
        />
        <CourseCategoryList
          coursePersonalClientListRes={coursePersonalClientListRes}
          page={page}
          setPage={(page) => redirect(PageRouteName.CourseCategoryStorePage, { page, name: searchName, courseCategoryId })}
        />
      </Suspense>
    </WrapperStyled>
  );
};

const WrapperStyled = styled(Stack)`
`;

export default CourseCategoryPage;
