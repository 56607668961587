import { parseRequestParams } from "utils/dataMappers";
import { publicRequest } from "../configs/requestApis";
import { FetchCourseBuyerParams } from "./courseBuyerParam";
import { CourseBuyerData } from "models/classes/courseBuyer.class";

export const fetchCourseBuyerCheckingApi = async ({
  newAbortSignal,
  ...params
}: FetchCourseBuyerParams) =>
  publicRequest.get(
    `/course/buyer/checking`,
    parseRequestParams(params, { newAbortSignal }, true)
  ) as any;

export const fetchCourseBuyerListByStudentApi = async ({
  page,
  newAbortSignal,
  ...params
}: FetchCourseBuyerParams) =>
  publicRequest.get(
    `/course/buyer/list`,
    parseRequestParams(params, { newAbortSignal, page }, true)
  ) as any;

export const fetchCourseBuyerListByAdminApi = async ({
  newAbortSignal,
  searchName,
  page,
  pageSize,
  ...params
}: FetchCourseBuyerParams) =>
  publicRequest.get(
    `/course/buyer/admin/management`,
    parseRequestParams(
      params,
      { searchName, page, pageSize, newAbortSignal },
      true
    )
  ) as any;

export const fetchCourseBuyerCreateApi = async (body: CourseBuyerData) =>
  publicRequest.post(`/course/buyer/teacher/create`, body) as any;
