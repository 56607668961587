import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

// HOOKS
import useRoute from "hooks/useRoute";
import { useSearchParams } from "react-router-dom";
import { useCourseBuyerListByStudentApi } from "apis/courseBuyerApis/useCourseBuyerApis";

// COMPONENTS
import { Box, Stack, useTheme } from "@mui/material";
import Button from "components/formControls/Button";
import LoadingImage from "components/progressBar/LoadingImage";

import PaginationItem from "views/PaginationItem";

// CONTANTS
import { PageRouteName } from "globals/enums/routes.enum";

// ICONS
import PersonIcon from '@mui/icons-material/Person';

const ProfilePurchased = React.memo(() => {
  // instances
  const theme = useTheme();
  const { t } = useTranslation();
  const { redirect } = useRoute();
  const [searchParams] = useSearchParams();
  const getPage = searchParams.get('page') ? Number(searchParams.get('page')) : 1;

  // states
  const [page, setPage] = useState(getPage);

  // hooks
  const { courseBuyerListByStudent } = useCourseBuyerListByStudentApi({ page });

  useEffect(() => {
    let queryParams = {};
    if (page) queryParams['page'] = page;
    redirect(PageRouteName.ProfilePurchasedPage, queryParams);
  }, [page]);

  return (
    <WrapperStyled
      padding={{ xs: '1rem', md: '1rem 2rem' }}
      bgcolor={theme.palette.default.dark}
      width={{ xs: '-webkit-fill-available' }}
      flexDirection="column"
      alignItems="center"
      borderRadius={3}
      rowGap={{ xs: '2rem' }}
    >
      <Stack flexDirection="column" rowGap={{ xs: '1.5rem' }} width={{ xs: '100%' }}>
        {(courseBuyerListByStudent?.list || [])?.map((item) => (
          <Stack
            key={item.id}
            bgcolor={theme.palette.accent.dark}
            padding={{ xs: '1rem' }}
            borderRadius={2}
            flexDirection={{ xs: 'row' }}
            alignItems={{ xs: 'center' }}
          >
            <Stack flex={1} flexDirection={{ xs: 'row' }} columnGap={{ xs: '.5rem', md: '1rem' }} alignItems={{ xs: 'center' }}>
              <Box width={{ xs: '100px' }} height={{ xs: '60px' }} borderRadius={1} overflow="hidden">
                <LoadingImage src={item.image!} width="100%" height="100%" alt="Khoá học online - Cùng Học Onine" />
              </Box>
              <Stack
                flexDirection={{ xs: 'column' }}
                rowGap={{ xs: '.25rem' }}
              >
                <Box fontSize={{ xs: 12, md: 16 }} color={theme.palette.text.light}>{item.name}</Box>
                <Box fontSize={{ xs: 11, md: 14 }} color={theme.palette.text.dark} fontStyle="italic">{item.description}</Box>
              </Stack>
            </Stack>
            <Stack flexDirection={{ xs: 'column' }} flex={.4} rowGap={{ xs: '.25rem' }}>
              <Stack flexDirection={{ xs: 'row' }} justifyContent={{ xs: 'center' }} alignItems={{ xs: 'center' }} columnGap={{ xs: '.25rem' }}>
                <PersonIcon style={{ color: theme.palette.text.light, fontSize: 18 }} />
                <Box fontSize={{ xs: 12, md: 14 }} color={theme.palette.text.light}>{t('Teacher')}</Box>
              </Stack>
              <Box textAlign="center" fontSize={{ xs: 12, md: 14 }} color={theme.palette.text.dark} fontStyle="italic">{item.author}</Box>
            </Stack>
            <Stack
              flexDirection={{ xs: 'column' }}
              justifyContent={{ xs: 'center' }}
              alignItems={{ xs: 'center' }}
              flex={.4}
              rowGap={{ xs: '.25rem' }}
            >
              <Button
                onClick={() => redirect(`${PageRouteName.CourseCategoryStorePage}/${item.id}`)}
                color="primary"
                width="120px"
                style={{ fontWeight: 300, padding: '.5rem' }}
              >
                {t('View now')}
              </Button>
            </Stack>
          </Stack>
        ))}
      </Stack>
      <PaginationItem
        page={page}
        setPage={(page) => setPage(page)}
        totalPage={Math.ceil(Number(courseBuyerListByStudent?.total) / Number(courseBuyerListByStudent?.pageSize)) || 1}
      />
    </WrapperStyled>
  );
});

const WrapperStyled = styled(Stack)`

`;

export default ProfilePurchased;
