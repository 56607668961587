import i18n from "i18next";
import { initReactI18next } from "react-i18next";

import locale from "./locales.json";

// CONSTANTS
import { SessionKey, StorageKey } from "globals/constants/storage";
import { Lang } from "globals/constants/i18n";
import env from "globals/constants/env";

const {
  languages,
  defaultLanguage,
  namespaces,
  defaultNamespace,
  namespaceSeparator,
  keySeparator,
  pluralSeparator,
} = locale as {
  languages: Lang[];
  defaultLanguage: Lang;
  [name: string]: any;
};

const resources = {} as { [lang: string]: { [namespace: string]: any } };
languages.forEach((lang: string) => {
  resources[lang] = {};
  namespaces.forEach((namespace: string) => {
    const translations = require(`./${namespace}/${lang}.json`);
    resources[lang][namespace] = translations;
  });
});

export const saveLangCode = (lang: Lang) =>
  sessionStorage.setItem(SessionKey.Language, lang);

export const getLangCode = (): Lang => {
  // todo: only ENGLISH is available at the moment, remove this when all traslated
  // return defaultLanguage;

  // Return saved language code from localStorage
  let lang: Lang = sessionStorage.getItem(SessionKey.Language)
    ? (sessionStorage.getItem(SessionKey.Language) as Lang)
    : "vi";

  if (lang && resources.hasOwnProperty(lang)) return lang;
  // If language code is not saved, guest language based on browser
  lang = navigator.language.split(/[-_]/)[0] as Lang | "vi";

  if (!lang || !resources.hasOwnProperty(lang)) {
    lang = defaultLanguage;
  }

  saveLangCode(lang);

  return lang;
};

const lang = getLangCode();

i18n.use(initReactI18next).init({
  resources,
  debug: env.ENV !== "production",

  lng: lang,

  ns: namespaces,
  defaultNS: defaultNamespace,

  nsSeparator: namespaceSeparator,
  keySeparator: keySeparator,
  pluralSeparator: pluralSeparator,

  interpolation: {
    escapeValue: false, // not needed for react as it escapes by default
  },
});

export default i18n;
