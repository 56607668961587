export enum Roles {
  STUDENT = "STUDENT",
  TEACHER = "TEACHER",
  ADMIN = "ADMIN",
}

export const RoleOptions = [
  { label: "Học viên", value: Roles.STUDENT },
  { label: "Giảng viên", value: Roles.TEACHER },
];
