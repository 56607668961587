import React from "react";

// COMPONENTS
import { Box, Stack, useTheme } from "@mui/material";
import Button from "components/formControls/Button";
import LoadingImage from "components/progressBar/LoadingImage";

// ICONS
import EditIcon from '@mui/icons-material/Edit';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import PublicIcon from '@mui/icons-material/Public';
import PublicOffIcon from '@mui/icons-material/PublicOff';
import VideoSettingsIcon from '@mui/icons-material/VideoSettings';

// UNTILS
import { CoursePersonalStatus } from "globals/enums/courses.enum";
import { PageRouteName } from "globals/enums/routes.enum";
import useRoute from "hooks/useRoute";
import { CoursePersonalDetailDto } from "models/classes/coursePersonal.class";
import { useTranslation } from "react-i18next";
import { generateTime } from "utils/dataHelpers";

type Props = {
  showBtnEdit?: boolean;
  coursePersonal: CoursePersonalDetailDto | null;
  setIsDialogSaller?: (value: boolean) => void;
  setSelectedCourse?: (course: CoursePersonalDetailDto) => void;
};

const CourseItem = React.memo(({
  showBtnEdit = true,
  coursePersonal,
  setIsDialogSaller,
  setSelectedCourse,
}: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const { redirect } = useRoute();

  return (
    <Stack
      key={coursePersonal?.id || 0}
      bgcolor={theme.palette.accent.dark}
      padding={{ xs: '1rem' }}
      borderRadius={2}
      flexDirection={{ xs: 'row' }}
      alignItems={{ xs: 'center' }}
    >
      <Stack flex={1} flexDirection={{ xs: 'row' }} columnGap={{ xs: '.5rem', md: '1rem' }} alignItems={{ xs: 'center' }}>
        <Box width={{ xs: '100px' }} height={{ xs: '60px' }} borderRadius={1} overflow="hidden">
          <LoadingImage src={coursePersonal?.image!} width="100%" height="100%" alt="Khoá học online - Cùng Học Onine" />
        </Box>
        <Stack
          flexDirection={{ xs: 'column' }}
          rowGap={{ xs: '.25rem' }}
          flex={1}
        >
          <Box fontSize={{ xs: 12, md: 16 }} color={theme.palette.text.light}>{coursePersonal?.name}</Box>
          <Box fontSize={{ xs: 11, md: 14 }} color={theme.palette.text.dark} fontStyle="italic">{coursePersonal?.description}</Box>
        </Stack>
      </Stack>
      <Stack flexDirection={{ xs: 'column' }} flex={.4} rowGap={{ xs: '.25rem' }}>
        <Stack flexDirection={{ xs: 'row' }} justifyContent={{ xs: 'center' }} alignItems={{ xs: 'center' }} columnGap={{ xs: '.25rem' }}>
          <LocalOfferIcon style={{ color: theme.palette.text.light, fontSize: 18 }} />
          <Box fontSize={{ xs: 12, md: 14 }} color={theme.palette.text.light}>{t('Pricing')}</Box>
        </Stack>
        <Box textAlign="center" fontSize={{ xs: 12, md: 14 }} color={theme.palette.text.dark} fontStyle="italic">{coursePersonal?.isPublic ? t('Public') : t('Unpublic')}</Box>
      </Stack>
      <Stack flexDirection={{ xs: 'column' }} flex={.4} rowGap={{ xs: '.25rem' }}>
        <Stack flexDirection={{ xs: 'row' }} justifyContent={{ xs: 'center' }} alignItems={{ xs: 'center' }} columnGap={{ xs: '.25rem' }}>
          <VideoSettingsIcon style={{ color: theme.palette.text.light, fontSize: 18 }} />
          <Box fontSize={{ xs: 12, md: 14 }} color={theme.palette.text.light}>{t('Status')}</Box>
        </Stack>
        <Box textAlign="center" fontSize={{ xs: 12, md: 14 }} color={theme.palette.text.dark} fontStyle="italic">{t(coursePersonal?.status!)}</Box>
      </Stack>
      <Stack flexDirection={{ xs: 'column' }} flex={.6} rowGap={{ xs: '.25rem' }}>
        <Stack flexDirection={{ xs: 'row' }} justifyContent={{ xs: 'center' }} alignItems={{ xs: 'center' }} columnGap={{ xs: '.25rem' }}>
          <VideoSettingsIcon style={{ color: theme.palette.text.light, fontSize: 18 }} />
          <Box fontSize={{ xs: 12, md: 14 }} color={theme.palette.text.light}>{t('Expired date')}</Box>
        </Stack>
        <Box textAlign="center" fontSize={{ xs: 12, md: 14 }} color={theme.palette.text.dark} fontStyle="italic">
          {coursePersonal?.isPublic
            ? t('Unlimited')
            : coursePersonal?.updated_at
              ? Number(new Date().getTime()) < Number(coursePersonal?.updated_at)
                ? generateTime(Number(coursePersonal?.updated_at)) : t('Expired')
              : t('Not activated')
          }
        </Box>
      </Stack>
      <Stack
        flexDirection={{ xs: 'row' }}
        justifyContent={{ xs: 'center' }}
        alignItems={{ xs: 'center' }}
        flex={.4}
        rowGap={{ xs: '.25rem' }}
        columnGap={{ xs: '1rem' }}
      >
        <Button
          onClick={() => {
            setIsDialogSaller && setIsDialogSaller(true);
            setSelectedCourse && setSelectedCourse(coursePersonal!);
          }}
          color="success"
          width="100%"
          style={{ fontWeight: 300, padding: '.5rem' }}
        >
          {coursePersonal?.status === CoursePersonalStatus.OFFSALE
            ? <PublicOffIcon style={{ color: theme.palette.text.light, fontSize: 24 }} />
            : <PublicIcon style={{ color: theme.palette.text.light, fontSize: 24 }} />
          }
        </Button>
        {showBtnEdit && (
          <Button
            onClick={() => redirect(`${PageRouteName.ProfileSallerUpdatePage}/${coursePersonal?.id}`)}
            color="primary"
            width="100%"
            style={{ fontWeight: 300, padding: '.5rem' }}
          >
            <EditIcon style={{ color: theme.palette.text.light, fontSize: 24 }} />
          </Button>
        )}
      </Stack>
    </Stack>
  );
});

export default CourseItem;
