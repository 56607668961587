import BaseModel from "./base.class";

export class CourseDescriptionByCourseDetailDto extends BaseModel<CourseDescriptionByCourseDetailDto> {
  id?: number;
  name?: string;
  coursePersonalId?: number;
  courseName?: string;
}

export type CourseDescriptionListRes = {
  list: CourseDescriptionByCourseDetailDto[];
  total: number;
  page: number;
  pageSize: number;
};

export type CourseDescriptionData = {
  name: string;
  coursePersonalId: number;
}
