import { Roles } from 'globals/enums/auth.enum';
import { UserDto, UserListRes } from 'models/classes/auth.class';
import {
  createContext,
  PropsWithChildren,
  useContext,
  useState
} from 'react';

type State = {
  token: string;
  expiredTime: string;
  role: Roles;
  userInfo: UserDto | null;
  userListRes: UserListRes | null;
};

type Actions = {
  updateToken: (token: string) => void;
  updateRole: (token: Roles) => void;
  updateExpiredTime: (expiredTime: string) => void;
  updateUserInfo: (userInfo: UserDto) => void;
  updateLogout: () => void;
  updateUserListRes: (users: UserListRes) => void;
};

const initState: State = {
  // authentication
  token: '',
  role: Roles.STUDENT,
  expiredTime: '',

  // user profile
  userInfo: null,
  userListRes: null,
};

const defaultActions: Actions = {
  updateToken: () => { },
  updateRole: () => { },
  updateExpiredTime: () => { },
  updateUserInfo: () => { },
  updateLogout: () => { },
  updateUserListRes: () => { },
};

const AuthContext = createContext<State & Actions>({
  ...initState,
  ...defaultActions,
});

export const AuthProvider = ({ children }: PropsWithChildren<{}>) => {
  const [token, setToken] = useState('');
  const [expiredTime, setExpiredTime] = useState('');
  const [role, setRole] = useState<Roles>(Roles.STUDENT);
  const [userInfo, setUserInfo] = useState<UserDto | null>(null);
  const [userListRes, setUserListRes] = useState<UserListRes | null>(null);

  const updateToken = (token: string) => {
    setToken(token);
  };

  const updateRole = (role: Roles) => {
    setRole(role);
  };

  const updateExpiredTime = (expiredTime: string) => {
    setExpiredTime(expiredTime);
  };

  const updateUserInfo = (userInfo: UserDto) => {
    setUserInfo(userInfo);
  };

  const updateUserListRes = (userList: UserListRes) => {
    setUserListRes(userList);
  };

  const updateLogout = () => {
    setToken('');
    setRole(Roles.STUDENT);
    setExpiredTime('');
    setUserInfo(null);
  };

  return (
    <AuthContext.Provider
      value={{
        token,
        updateToken,

        role,
        updateRole,

        expiredTime,
        updateExpiredTime,

        // user profile
        userInfo,
        updateUserInfo,

        // reset logout
        updateLogout,

        // user list
        userListRes,
        updateUserListRes,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);
