import { AlertType } from 'globals/constants/types';
import {
  createContext,
  PropsWithChildren,
  useContext,
  useState
} from 'react';

type State = {
  open: boolean;
  type: AlertType;
  message: string;
};

type Actions = {
  handleClose: () => void;
  updateSnackbar: (snackbar: State) => void;
};

const initState: State = {
  open: false,
  type: 'success',
  message: 'Welcome to Ninedev',
};

const defaultActions: Actions = {
  handleClose: () => { },
  updateSnackbar: () => { },
};

const SnackbarContext = createContext<State & Actions>({
  ...initState,
  ...defaultActions,
});

export const SnackbarProvider = ({ children }: PropsWithChildren<{}>) => {
  const [open, setOpen] = useState(initState.open);
  const [type, setType] = useState<AlertType>(initState.type);
  const [message, setMessage] = useState(initState.message);

  const handleClose = () => {
    setOpen(false);
  };

  const updateSnackbar = (snackbar: State) => {
    setOpen(snackbar.open);
    setType(snackbar.type!);
    setMessage(snackbar.message);
  };

  return (
    <SnackbarContext.Provider
      value={{
        open,
        type,
        message,
        handleClose,
        updateSnackbar,
      }}
    >
      {children}
    </SnackbarContext.Provider>
  );
};

export const useSnackbarContext = () => useContext(SnackbarContext);
