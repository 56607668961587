import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

// HOOKS
import useRoute from "hooks/useRoute";
import { useSearchParams } from "react-router-dom";
import { useSnackbarContext } from "store/snackbarContext";

// COMPONENTS
import { Box, Stack, useTheme } from "@mui/material";
import Button from "components/formControls/Button";
import SelectField from "components/formControls/Select";

// APIS
import { useCoursePersonalTeacherListByApi } from "apis/coursePersonalApis/useCoursePersonalApis";

// CONSTANTS
import TextField from "components/formControls/TextField";
import { SelectOption } from "globals/constants/types";
import { IsCreate, IsEdit } from "globals/enums/courses.enum";
import { CourseDescriptionData } from "models/classes/courseDescription.class";
import useLoadingCallback from "hooks/useLoadingCallback";
import { actionCourseDescriptionCreateApi, actionCourseDescriptionUpdateApi } from "apis/courseDescriptionApis/courseDescriptionAction";
import { useCourseDescriptionDetailByApi } from "apis/courseDescriptionApis/useCourseDescriptionApis";
import { isEmpty } from "lodash";

const ProfileDescriptionCreate = React.memo(() => {
  // instance
  const { t } = useTranslation();
  const theme = useTheme();
  const { goBack, params } = useRoute();
  const isView = params.id ? IsEdit : IsCreate;
  const [searchParams] = useSearchParams();
  const getCoursePersonalId = searchParams.get('coursePersonalId') ? Number(searchParams.get('coursePersonalId')) : -1;

  // states
  const [coursePersonalId, setCoursePersonalId] = useState(getCoursePersonalId);
  const [name, setName] = useState('');

  // hooks
  const { updateSnackbar } = useSnackbarContext();
  const { coursePersonalTeacherListRes } = useCoursePersonalTeacherListByApi({
    pageSize: 1000,
  });
  const { courseDescription } = useCourseDescriptionDetailByApi({
    coursePersonalId,
    courseDescriptionId: params.id
  })

  const [handleDescriptionCreate, isLoadingCreate] = useLoadingCallback(async () => {
    try {
      if (coursePersonalId === -1) {
        updateSnackbar({ type: 'error', message: 'Select course', open: true });
        return;
      } else if (!name) {
        updateSnackbar({ type: 'error', message: 'Enter your name description', open: true });
        return;
      }
      const values: CourseDescriptionData = {
        name,
        coursePersonalId,
      };
      const data = await actionCourseDescriptionCreateApi(values);
      if (data) {
        updateSnackbar({ type: 'success', message: 'Create success', open: true });
        goBack();
      } else {
        updateSnackbar({ type: 'error', message: 'Create fail', open: true });
      }
    } catch (error) {
      console.log('handleDescriptionCreate', error);
    }
  });

  const [handleDescriptionUpdate, isLoadingUpdate] = useLoadingCallback(async () => {
    try {
      if (coursePersonalId === -1) {
        updateSnackbar({ type: 'error', message: 'Select course', open: true });
        return;
      } else if (!name) {
        updateSnackbar({ type: 'error', message: 'Enter your name description', open: true });
        return;
      }
      const values: CourseDescriptionData = {
        name,
        coursePersonalId,
      };
      const data = await actionCourseDescriptionUpdateApi(params.id, values);
      if (data) {
        updateSnackbar({ type: 'success', message: 'Update success', open: true });
        goBack();
      } else {
        updateSnackbar({ type: 'error', message: 'Update fail', open: true });
      }
    } catch (error) {
      console.log('handleDescriptionUpdate', error);
    }
  });

  // effects
  useEffect(() => {
    if (!isEmpty(courseDescription)) {
      setCoursePersonalId(courseDescription.coursePersonalId!);
      setName(courseDescription.name!);
    }
  }, [courseDescription]);

  return (
    <WrapperStyled
      padding={{ xs: '2rem' }}
      bgcolor={theme.palette.default.dark}
      width={{ xs: '-webkit-fill-available' }}
      flexDirection={{ xs: 'column' }}
      rowGap={{ xs: '1.5rem' }}
      borderRadius={3}
    >
      <Stack flexDirection={{ xs: 'row' }} justifyContent={{ xs: 'space-between' }} mb={{ xs: '1rem' }}>
        <Box
          fontSize={{ xs: 24, md: 30 }}
          color={theme.palette.text.light}
        >
          {t('Create description')}
        </Box>
        <Button
          color="primary"
          style={{ fontWeight: 300 }}
          disabled={isLoadingUpdate || isLoadingCreate}
          onClick={isView === IsEdit ? handleDescriptionUpdate : handleDescriptionCreate}
        >
          {t('Confirm')}
        </Button>
      </Stack>
      <Stack flexDirection={{ xs: 'row' }} columnGap={{ xs: '2rem' }} width={{ xs: '100%' }}>
        <Stack flex={1} rowGap={{ xs: '.5rem' }}>
          <Box color={theme.palette.text.light}>{t('Select course')}</Box>
          <SelectField
            width="100%"
            placeHolder={t('Select course')}
            disabled={params.id ? true : false}
            selectedValue={coursePersonalId}
            options={coursePersonalTeacherListRes?.list.map(item => ({ label: item.name, value: item.id })) as SelectOption[]}
            onChange={(selectedValue) => setCoursePersonalId(Number(selectedValue))}
          />
        </Stack>
        <Stack flex={1} rowGap={{ xs: '.5rem' }}>
          <Box color={theme.palette.text.light}>{t('Name description')}</Box>
          <TextField
            placeholder={t('Enter your name description')}
            background={theme.palette.default.light}
            style={{ width: '100%' }}
            value={name || ''}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </Stack>
      </Stack>
    </WrapperStyled>
  );
});

const WrapperStyled = styled(Stack)`

`;

export default ProfileDescriptionCreate;
