export enum CoursePersonalStatus {
  ONSALE = "ONSALE",
  OFFSALE = "OFFSALE",
}

export enum VideoTypeEnum {
  DRIVE = "DRIVE",
  EMBED = "EMBED",
}

export const PricingOptions = [
  { label: "Miễn phí", value: 1 },
  { label: "Thu phí", value: 2 },
];

export const VideoTypeOptions = [
  { label: "Link Google Drive", value: VideoTypeEnum.DRIVE },
  { label: "Link Youtube", value: VideoTypeEnum.EMBED },
];

export const [IsEdit, IsCreate] = ["IsEdit", "IsCreate"];
