import { Box, Stack, useTheme } from "@mui/material"
import Button from "components/formControls/Button";
import { PageRouteName } from "globals/enums/routes.enum";
import useRoute from "hooks/useRoute";
import { useTranslation } from "react-i18next";

const NotFoundPage = () => {
  const { t } = useTranslation();
  const theme = useTheme();
  const { redirect } = useRoute();

  return (
    <Stack
      flexDirection={{ xs: 'column' }}
      justifyContent={{ xs: 'center' }}
      alignItems={{ xs: 'center' }}
      height={{ xs: '100vh' }}
      rowGap={{ xs: '.5rem' }}
    >
      <Box fontSize={{ xs: 160 }} color={theme.palette.text.light}>404</Box>
      <Box fontSize={{ xs: 16 }} color={theme.palette.text.light} mb={2}>{t('Not found page')}</Box>
      <Button style={{ fontWeight: 300 }} onClick={() => redirect(PageRouteName.DashboardPage)}>
        {t('Back')}
      </Button>
    </Stack>
  );
};

export default NotFoundPage;
