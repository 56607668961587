import { parseRequestParams } from "utils/dataMappers";
import { publicRequest } from "../configs/requestApis";
import { FetchCourseCategoryParams } from "./courseCategoryParam";
import { CourseCategoryData } from "models/classes/courseCategory.class";

export const fetchCourseCategoryListApi = async ({
  newAbortSignal,
  searchName,
  page,
  pageSize,
  ...params
}: FetchCourseCategoryParams) =>
  publicRequest.get(
    `/course/category/list`,
    parseRequestParams(
      params,
      { searchName, page, pageSize, newAbortSignal },
      true
    )
  ) as any;

export const fetchCourseCategoryAllApi = async ({
  newAbortSignal,
  ...params
}: FetchCourseCategoryParams) =>
  publicRequest.get(
    `/course/category/list/all`,
    parseRequestParams(params, { newAbortSignal }, true)
  ) as any;

export const fetchCourseCategoryDetailApi = async ({
  courseCategoryId,
  newAbortSignal,
  ...params
}: FetchCourseCategoryParams) =>
  publicRequest.get(
    `/course/category/admin/detail/${courseCategoryId}`,
    parseRequestParams(params, { newAbortSignal }, true)
  ) as any;

export const fetchCourseCategoryCreateApi = async (body: CourseCategoryData) =>
  publicRequest.post(`/course/category/admin/create`, body) as any;

export const fetchCourseCategoryUpdateApi = async (
  id: number,
  body: CourseCategoryData
) => publicRequest.put(`/course/category/admin/update/${id}`, body) as any;
