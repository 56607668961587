import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

// HOOKS
import useRoute from "hooks/useRoute";
import { useSearchParams } from "react-router-dom";
import { useSnackbarContext } from "store/snackbarContext";

// FORMS
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from 'react-hook-form';
import * as yup from "yup";

// COMPONENTS
import { Box, Stack, useTheme } from "@mui/material";
import Button from "components/formControls/Button";
import SelectField from "components/formControls/Select";
import TextField from "components/formHooks/TextField";

// APIS
import { useCoursePersonalTeacherListByApi } from "apis/coursePersonalApis/useCoursePersonalApis";
import { actionCourseSectionCreateApi, actionCourseSectionUpdateApi } from "apis/courseSectionApis/courseSectionAction";
import { useCourseSectionDetailByApi } from "apis/courseSectionApis/useCourseSectionApis";

// CONSTANTS
import { SelectOption } from "globals/constants/types";
import { IsCreate, IsEdit } from "globals/enums/courses.enum";
import { CourseSectionData } from "models/classes/courseSection.class";

const ProfileSectionCreate = React.memo(() => {
  // instance
  const { t } = useTranslation();
  const theme = useTheme();
  const { goBack, params } = useRoute();
  const isView = params.id ? IsEdit : IsCreate;
  const [searchParams] = useSearchParams();
  const getCoursePersonalId = searchParams.get('coursePersonalId') ? Number(searchParams.get('coursePersonalId')) : -1;

  // states
  const [coursePersonalId, setCoursePersonalId] = useState(getCoursePersonalId);

  // hooks
  const { updateSnackbar } = useSnackbarContext();
  const { coursePersonalTeacherListRes } = useCoursePersonalTeacherListByApi({
    pageSize: 1000,
  });
  const { section } = useCourseSectionDetailByApi({ courseSectionId: params.id, coursePersonalId });

  // forms
  const sectionSchema = yup.object({
    index: yup.number().required('This value cannot be blank').max(100).nullable(),
    name: yup.string().required('This value cannot be blank').nullable(),
  });

  const { control, handleSubmit, reset } = useForm<any>({
    resolver: yupResolver(sectionSchema),
  });

  const handleSectionCreate = async (values: CourseSectionData) => {
    try {
      if (coursePersonalId === -1) {
        updateSnackbar({ type: 'error', message: 'Select course', open: true });
        return;
      }
      values = {
        ...values,
        coursePersonalId,
      };
      const data = await actionCourseSectionCreateApi(values);
      if (data) {
        updateSnackbar({ type: 'success', message: 'Create success', open: true });
        goBack();
      } else {
        updateSnackbar({ type: 'error', message: 'Create fail', open: true });
      }
    } catch (error) {
      console.log('handleSectionCreate', error);
    }
  };

  const handleSectionUpdate = async (values: CourseSectionData) => {
    try {
      if (coursePersonalId === -1) {
        updateSnackbar({ type: 'error', message: 'Select course', open: true });
        return;
      }
      values = {
        ...values,
        coursePersonalId,
      };
      const data = await actionCourseSectionUpdateApi(section?.id!, values);
      if (data) {
        updateSnackbar({ type: 'success', message: 'Update success', open: true });
        goBack();
      } else {
        updateSnackbar({ type: 'error', message: 'Update fail', open: true });
      }
    } catch (error) {
      console.log('handleSectionUpdate', error);
    }
  };

  // effects
  useEffect(() => {
    if (isView === IsEdit) {
      reset({
        index: section?.index,
        name: section?.name,
      });
      setCoursePersonalId(section?.coursePersonalId!);
    }
  }, [section]);

  return (
    <WrapperStyled
      padding={{ xs: '2rem' }}
      bgcolor={theme.palette.default.dark}
      width={{ xs: '-webkit-fill-available' }}
      flexDirection={{ xs: 'column' }}
      rowGap={{ xs: '1.5rem' }}
      borderRadius={3}
    >
      <Stack flexDirection={{ xs: 'row' }} justifyContent={{ xs: 'space-between' }} mb={{ xs: '1rem' }}>
        <Box
          fontSize={{ xs: 24, md: 30 }}
          color={theme.palette.text.light}
        >
          {t('Create section')}
        </Box>
        <Button
          color="primary"
          style={{ fontWeight: 300 }}
          onClick={isView === IsEdit ? handleSubmit(handleSectionUpdate) : handleSubmit(handleSectionCreate)}
        >
          {t('Confirm')}
        </Button>
      </Stack>
      <Stack flexDirection={{ xs: 'row' }} columnGap={{ xs: '2rem' }} width={{ xs: '100%' }}>
        <Stack flex={1} rowGap={{ xs: '.5rem' }}>
          <Box color={theme.palette.text.light}>{t('Select course')}</Box>
          <SelectField
            width="100%"
            placeHolder={t('Select course')}
            disabled={params.id ? true : false}
            selectedValue={coursePersonalId}
            options={coursePersonalTeacherListRes?.list.map(item => ({ label: item.name, value: item.id })) as SelectOption[]}
            onChange={(selectedValue) => setCoursePersonalId(Number(selectedValue))}
          />
        </Stack>
        <Stack flex={1} rowGap={{ xs: '.5rem' }}>
          <Box color={theme.palette.text.light}>{t('Name section')}</Box>
          <TextField
            control={control}
            name="name"
            placeholder={t('Enter your section course')}
            background={theme.palette.default.light}
            style={{ width: '100%' }}
            required
          />
        </Stack>
      </Stack>
      <Stack flexDirection={{ xs: 'row' }} columnGap={{ xs: '2rem' }} width={{ xs: '100%' }}>
        <Stack flex={1} rowGap={{ xs: '.5rem' }}>
          <Box color={theme.palette.text.light}>{t('Index section')}</Box>
          <TextField
            control={control}
            name="index"
            placeholder={t('Enter your index section course')}
            background={theme.palette.default.light}
            style={{ width: '100%' }}
            typeInput="number"
            required
          />
        </Stack>
        <Stack flex={1} rowGap={{ xs: '.5rem' }}>
        </Stack>
      </Stack>
    </WrapperStyled>
  );
});

const WrapperStyled = styled(Stack)``;

export default ProfileSectionCreate;
