import useAbortRequest from "hooks/useAbortRequest";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import { actionAuthDetailProfileApi, actionUserListResApi } from "./authAction";
import { useAuthContext } from "./authContext";
import { FetchAuthParams } from "./authParam";

export const useDetailProfileByApi = () => {
  const { newAbortSignal, abortRequest } = useAbortRequest();

  const { userInfo, updateUserInfo } = useAuthContext();

  const fetchDetailProfileByApi = async () => {
    await abortRequest();
    const data = await actionAuthDetailProfileApi({
      newAbortSignal,
    });
    if (!isEmpty(data)) {
      updateUserInfo(data);
    }
  };

  // Unmout Apis
  useEffect(() => {
    if (isEmpty(userInfo)) {
      fetchDetailProfileByApi();
    }
    return () => {
      (async () => {
        await abortRequest();
      })();
    };
  }, []);

  return {
    userInfo,
    fetchDetailProfileByApi,
  };
};

export const useUserListResApi = ({ searchName, page }: FetchAuthParams) => {
  const { newAbortSignal, abortRequest } = useAbortRequest();

  const { userListRes, updateUserListRes } = useAuthContext();

  const fetchUserListResByApi = async () => {
    await abortRequest();
    const data = await actionUserListResApi({
      searchName,
      page,
      newAbortSignal,
    });
    if (!isEmpty(data)) {
      updateUserListRes(data!);
    }
  };

  // Unmout Apis
  useEffect(() => {
    if (isEmpty(userListRes) || searchName || page) {
      fetchUserListResByApi();
    }
    return () => {
      (async () => {
        await abortRequest();
      })();
    };
  }, [searchName, page]);

  return {
    userListRes,
    fetchUserListResByApi,
  };
};
