import { CourseLessonData } from "models/classes/courseLesson.class";
import { parseRequestParams } from "utils/dataMappers";
import { publicRequest } from "../configs/requestApis";
import { FetchCourseLessonParams } from "./courseLessonParam";

export const fetchCourseLessonApi = async ({
  newAbortSignal,
  ...params
}: FetchCourseLessonParams) =>
  publicRequest.get(
    `/course/lesson/client/list`,
    parseRequestParams(params, { newAbortSignal }, true)
  ) as any;

export const fetchLessonDetailApi = async ({
  newAbortSignal,
  ...params
}: FetchCourseLessonParams) =>
  publicRequest.get(
    `/course/lesson/client/detail`,
    parseRequestParams(params, { newAbortSignal }, true)
  ) as any;

export const fetchLessonDetailByAdminApi = async ({
  courseLessonId,
  newAbortSignal,
  ...params
}: FetchCourseLessonParams) =>
  publicRequest.get(
    `/course/lesson/detail/${courseLessonId}`,
    parseRequestParams(params, { newAbortSignal }, true)
  ) as any;

export const fetchCourseLessonListApi = async ({
  searchName,
  page,
  pageSize,
  newAbortSignal,
  ...params
}: FetchCourseLessonParams) =>
  publicRequest.get(
    `/course/lesson/list`,
    parseRequestParams(
      params,
      { searchName, page, pageSize, newAbortSignal },
      true
    )
  ) as any;

export const fetchCourseLessonCreateApi = async (body: CourseLessonData) =>
  publicRequest.post(`/course/lesson/create`, body) as any;

export const fetchCourseLessonUpdateApi = async (
  id: number,
  body: CourseLessonData
) => publicRequest.put(`/course/lesson/update/${id}`, body) as any;

export const fetchCourseLessonDeleteApi = async ({
  courseLessonId,
  coursePersonalId,
}: FetchCourseLessonParams) =>
  publicRequest.delete(
    `/course/lesson/delete/${courseLessonId}?coursePersonalId=${coursePersonalId}`
  ) as any;
