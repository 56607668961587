import axios, { AxiosResponse, AxiosError, AxiosRequestConfig, AxiosInstance } from 'axios';

export type Interceptor = {
  onRequest?: (requestConfig: AxiosRequestConfig) => AxiosRequestConfig | any;
  onRequestError?: (error: AxiosError) => Promise<AxiosError>;
  onResponse?: (response: AxiosResponse) => AxiosResponse<any>;
  onResponseError?: (error: AxiosError) => Promise<AxiosError>;
};

export type RequestConfigs = Omit<AxiosRequestConfig, 'baseURL'> & {
  baseURL: string;
};

export const createRequestInstance = (
  configs: RequestConfigs,
  interceptors?: Interceptor[]
): AxiosInstance => {
  const request = axios.create(configs);
  
  (interceptors || []).forEach(interceptor => {
    if (interceptor.onRequest || interceptor.onRequestError) {
      request.interceptors.request.use(interceptor.onRequest, interceptor.onRequestError);
    }
    
    if (interceptor.onResponse || interceptor.onResponseError) {
      request.interceptors.response.use(interceptor.onResponse, interceptor.onResponseError);
    }
  });
  
  return request;
};
