import { useAuthContext } from "apis/authApis/authContext";
import { StorageKey } from "globals/constants/storage";
import { PageRouteName } from "globals/enums/routes.enum";
import useRoute from "hooks/useRoute";
import { useEffect } from "react";
import { removeStorage } from "services/storageService";

const LogoutPage = () => {
  const { redirect } = useRoute();
  const { updateLogout } = useAuthContext();
  useEffect(() => {
    removeStorage(StorageKey.AccessToken);
    removeStorage(StorageKey.Role);
    removeStorage(StorageKey.ExpiredTime);
    updateLogout();
    redirect(PageRouteName.DashboardPage);
  }, []);
  return null;
};

export default LogoutPage;
