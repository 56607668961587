import { parseRequestParams } from "utils/dataMappers";
import { publicRequest } from "../configs/requestApis";
import { FetchCourseSectionParams } from "./courseSectionParam";
import { CourseSectionData } from "models/classes/courseSection.class";

export const fetchCourseSectionListApi = async ({
  searchName,
  page,
  pageSize,
  newAbortSignal,
  ...params
}: FetchCourseSectionParams) =>
  publicRequest.get(
    `/course/section/list`,
    parseRequestParams(
      params,
      { searchName, page, pageSize, newAbortSignal },
      true
    )
  ) as any;

export const fetchCourseSectionAllApi = async ({
  newAbortSignal,
  ...params
}: FetchCourseSectionParams) =>
  publicRequest.get(
    `/course/section/all`,
    parseRequestParams(params, { newAbortSignal }, true)
  ) as any;

export const fetchCourseSectionCreateApi = async (body: CourseSectionData) =>
  publicRequest.post(`/course/section/create`, body) as any;

export const fetchCourseSectionDeleteApi = async ({
  courseSectionId,
  coursePersonalId,
}: FetchCourseSectionParams) =>
  publicRequest.delete(
    `/course/section/delete/${courseSectionId}?coursePersonalId=${coursePersonalId}`
  ) as any;

export const fetchCourseSectionDetailApi = async ({
  courseSectionId,
  newAbortSignal,
  ...params
}: FetchCourseSectionParams) =>
  publicRequest.get(
    `/course/section/detail/${courseSectionId}`,
    parseRequestParams(params, { newAbortSignal }, true)
  ) as any;

export const fetchCourseSectionUpdateApi = async (
  id: number,
  body: CourseSectionData
) => publicRequest.put(`/course/section/update/${id}`, body) as any;
