import useAbortRequest from "hooks/useAbortRequest";
import { isEmpty } from "lodash";
import { useEffect } from "react";
import {
  actionCourseBuyerByAdminApi,
  actionCourseBuyerListByStudentApi,
} from "./courseBuyerAction";
import { useCourseBuyerContext } from "./courseBuyerContext";
import { FetchCourseBuyerParams } from "./courseBuyerParam";

export const useCourseBuyerListByStudentApi = ({
  page,
}: FetchCourseBuyerParams) => {
  const { newAbortSignal, abortRequest } = useAbortRequest();

  const { courseBuyerListByStudent, updateCourseBuyerListStudent } =
    useCourseBuyerContext();

  const fetchCourseBuyerListByStudentApi = async () => {
    await abortRequest();
    const data = await actionCourseBuyerListByStudentApi({
      newAbortSignal,
      page,
    });
    if (!isEmpty(data)) {
      updateCourseBuyerListStudent(data);
    }
  };

  // Unmout Apis
  useEffect(() => {
    if (isEmpty(courseBuyerListByStudent) || page) {
      fetchCourseBuyerListByStudentApi();
    }
    return () => {
      (async () => {
        await abortRequest();
      })();
    };
  }, [page]);

  return {
    courseBuyerListByStudent,
    fetchCourseBuyerListByStudentApi,
  };
};

export const useCourseBuyerListByAdminApi = ({
  coursePersonalId,
  searchName,
  page,
}: FetchCourseBuyerParams) => {
  const { newAbortSignal, abortRequest } = useAbortRequest();

  const { courseBuyerListByAdminRes, updateCourseBuyerListAdminRes } =
    useCourseBuyerContext();

  const fetchCourseBuyerListByAdminApi = async () => {
    await abortRequest();
    const data = await actionCourseBuyerByAdminApi({
      page,
      searchName,
      coursePersonalId,
      newAbortSignal,
    });
    if (!isEmpty(data)) {
      updateCourseBuyerListAdminRes(data!);
    }
  };

  // Unmout Apis
  useEffect(() => {
    if (isEmpty(courseBuyerListByAdminRes) || searchName || page) {
      fetchCourseBuyerListByAdminApi();
    }
    return () => {
      (async () => {
        await abortRequest();
      })();
    };
  }, [searchName, page]);

  return {
    courseBuyerListByAdminRes,
    fetchCourseBuyerListByAdminApi,
  };
};
