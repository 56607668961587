import React from "react";

// COMPONENTS
import { Box, Stack, useTheme } from "@mui/material";

// UNTILS
import { CourseBuyerListByAdminDto } from "models/classes/courseBuyer.class";
import { useTranslation } from "react-i18next";

type Props = {
  buyerDetail: CourseBuyerListByAdminDto | null;
};

const BuyerItem = React.memo(({ buyerDetail }: Props) => {
  const theme = useTheme();
  const { t } = useTranslation();

  return (
    <Stack
      bgcolor={theme.palette.accent.dark}
      padding={{ xs: '1rem' }}
      borderRadius={2}
      flexDirection={{ xs: 'row' }}
      alignItems={{ xs: 'center' }}
      columnGap={{ xs: '1rem' }}
    >
      <Stack flexDirection={{ xs: 'column' }} flex={1} rowGap={{ xs: '.25rem' }}>
        <Stack flexDirection={{ xs: 'row' }} alignItems={{ xs: 'center' }}>
          <Box fontSize={{ xs: 12, md: 14 }} color={theme.palette.text.light}>{t('Name course')}</Box>
        </Stack>
        <Box fontSize={{ xs: 12, md: 14 }} color={theme.palette.text.dark} fontStyle="italic">
          {buyerDetail?.coursePersonal?.name}
        </Box>
      </Stack>
      <Stack flexDirection={{ xs: 'column' }} flex={1} rowGap={{ xs: '.25rem' }}>
        <Stack flexDirection={{ xs: 'row' }} alignItems={{ xs: 'center' }}>
          <Box fontSize={{ xs: 12, md: 14 }} color={theme.palette.text.light}>{t('Name student')}</Box>
        </Stack>
        <Box fontSize={{ xs: 12, md: 14 }} color={theme.palette.text.dark} fontStyle="italic">
          {buyerDetail?.user?.name}
        </Box>
      </Stack>
      <Stack flexDirection={{ xs: 'column' }} flex={1} rowGap={{ xs: '.25rem' }}>
        <Stack flexDirection={{ xs: 'row' }} alignItems={{ xs: 'center' }}>
          <Box fontSize={{ xs: 12, md: 14 }} color={theme.palette.text.light}>{t('Email')}</Box>
        </Stack>
        <Box fontSize={{ xs: 12, md: 14 }} color={theme.palette.text.dark} fontStyle="italic">
          {buyerDetail?.user?.email}
        </Box>
      </Stack>
    </Stack >
  );
});

export default BuyerItem;
