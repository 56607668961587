import {
  Interceptor,
  RequestConfigs,
  createRequestInstance,
} from "utils/requestHelpers";

import {
  abortInterceptor,
  customerInterceptor,
  dataInterceptor,
} from "./interceptors";

// CONSTANTS
// export const baseURL = 'https://ninedev.net/backend/api';
export const baseURL = process.env.REACT_APP_API_URL;
export const baseImageURL = process.env.REACT_APP_IMAGE_URL;
export const baseBlogURL = process.env.REACT_APP_BLOG_URL;

// Auto add common interceptors
const createRequest = (configs: RequestConfigs, interceptors?: Interceptor[]) =>
  createRequestInstance(configs, [
    ...(interceptors || []),
    dataInterceptor,
    abortInterceptor,
  ]);

export const publicRequest = createRequest(
  {
    baseURL: `${baseURL}`,
    timeout: 100000,
  },
  [customerInterceptor]
);
