import { isEmpty } from "lodash";
import {
  CourseBuyerData,
  CourseBuyerDto,
  CourseBuyerListByAdminDto,
} from "models/classes/courseBuyer.class";
import {
  fetchCourseBuyerCheckingApi,
  fetchCourseBuyerCreateApi,
  fetchCourseBuyerListByAdminApi,
  fetchCourseBuyerListByStudentApi
} from "./courseBuyerFetch";
import { FetchCourseBuyerParams } from "./courseBuyerParam";

export const actionCourseBuyerCheckingApi = async ({
  ...params
}: FetchCourseBuyerParams) => {
  try {
    const { data } = await fetchCourseBuyerCheckingApi(params);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const actionCourseBuyerListByStudentApi = async ({
  ...params
}: FetchCourseBuyerParams) => {
  try {
    const { data } = await fetchCourseBuyerListByStudentApi(params);
    if (!isEmpty(data)) {
      return {
        list: data.list.map((item: CourseBuyerDto) =>
          new CourseBuyerDto().fromPayload(item)
        ),
        page: data.page,
        pageSize: data.pageSize,
        total: data.total,
      };
    }
  } catch (error) {
    console.log(error);
  }
};

export const actionCourseBuyerByAdminApi = async ({
  ...params
}: FetchCourseBuyerParams) => {
  try {
    const { data } = await fetchCourseBuyerListByAdminApi(params);
    if (!isEmpty(data)) {
      return {
        list: data.list.map((item: CourseBuyerListByAdminDto) =>
          new CourseBuyerListByAdminDto().fromPayload(item)
        ),
        page: data.page,
        pageSize: data.pageSize,
        total: data.total,
      };
    }
  } catch (error) {
    console.log(error);
  }
};

export const actionCourseBuyerCreateApi = async (body: CourseBuyerData) => {
  try {
    const { data } = await fetchCourseBuyerCreateApi(body);
    return data;
  } catch (error) {
    console.log(error);
  }
};
