import { isEmpty } from "lodash";
import {
  CourseSectionByCourseDetailDto,
  CourseSectionData,
} from "models/classes/courseSection.class";
import {
  fetchCourseSectionAllApi,
  fetchCourseSectionCreateApi,
  fetchCourseSectionDeleteApi,
  fetchCourseSectionDetailApi,
  fetchCourseSectionListApi,
  fetchCourseSectionUpdateApi,
} from "./courseSectionFetch";
import { FetchCourseSectionParams } from "./courseSectionParam";

export const actionCourseSectionsApi = async ({
  ...params
}: FetchCourseSectionParams) => {
  try {
    const { data } = await fetchCourseSectionListApi(params);
    if (!isEmpty(data)) {
      return {
        list: data.list.map((item: CourseSectionByCourseDetailDto) =>
          new CourseSectionByCourseDetailDto().fromPayload(item)
        ),
        page: data.page,
        pageSize: data.pageSize,
        total: data.total,
      };
    }
  } catch (error) {
    console.log(error);
  }
};

export const actionCourseSectionAllApi = async ({
  ...params
}: FetchCourseSectionParams) => {
  try {
    const { data } = await fetchCourseSectionAllApi(params);
    if (!isEmpty(data)) {
      return (data || []).map((item: CourseSectionByCourseDetailDto) =>
        new CourseSectionByCourseDetailDto().fromPayload(item)
      );
    }
  } catch (error) {
    console.log(error);
  }
};

export const actionCourseSectionCreateApi = async (body: CourseSectionData) => {
  try {
    const { data } = await fetchCourseSectionCreateApi(body);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const actionCourseSectionUpdateApi = async (
  id: number,
  body: CourseSectionData
) => {
  try {
    const { data } = await fetchCourseSectionUpdateApi(id, body);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const actionCourseSectionDeleteApi = async ({
  ...params
}: FetchCourseSectionParams) => {
  try {
    const { data } = await fetchCourseSectionDeleteApi(params);
    return data;
  } catch (error) {
    console.log(error);
  }
};

export const actionCourseSectionDetailApi = async ({
  ...params
}: FetchCourseSectionParams) => {
  try {
    const { data } = await fetchCourseSectionDetailApi(params);
    return new CourseSectionByCourseDetailDto().fromPayload(data);
  } catch (error) {
    console.log(error);
  }
};
