import React from "react";
import styled from "styled-components";

// HOOKS
import useRoute from "hooks/useRoute";
import { useSearchParams } from "react-router-dom";

// COMPONENTS
import { Stack, useTheme } from "@mui/material";
import ProfileSearchNavbar from "./ProfileSearchNavbar";

// CONSTANTS
import { useCourseBuyerListByAdminApi } from "apis/courseBuyerApis/useCourseBuyerApis";
import { PageRouteName } from "globals/enums/routes.enum";
import PaginationItem from "views/PaginationItem";
import BuyerItem from "../items/BuyerItem";

const ProfileStudent = React.memo(() => {
  // instances
  const theme = useTheme();
  const { redirect } = useRoute();
  const [searchParams] = useSearchParams();
  const searchName = searchParams.get('name') ? String(searchParams.get('name')) : '';
  const page = searchParams.get('page') ? Number(searchParams.get('page')) : 1;

  // hooks
  const { courseBuyerListByAdminRes } = useCourseBuyerListByAdminApi({
    searchName,
    page,
  });

  // functions
  const onSubmitSearch = (value: string) => {
    let queryParams = {};
    queryParams['page'] = 1;
    queryParams['name'] = value;
    redirect(PageRouteName.ProfileManageStudentPage, queryParams);
  };

  return (
    <WrapperStyled
      padding={{ xs: '1rem', md: '1rem 2rem' }}
      bgcolor={theme.palette.default.dark}
      width={{ xs: '-webkit-fill-available' }}
      flexDirection="column"
      alignItems="center"
      borderRadius={3}
      rowGap={{ xs: '1.5rem' }}
    >
      <ProfileSearchNavbar
        searchName={searchName}
        onSubmitSearch={onSubmitSearch}
        labelButton="Create student"
        onClickButton={() => redirect(PageRouteName.ProfileManageStudentCreatePage)}
      />
      <Stack flexDirection="column" rowGap={{ xs: '1.5rem' }} width={{ xs: '100%' }}>
        {(courseBuyerListByAdminRes?.list || [])?.map((item) => (
          <BuyerItem
            key={item.id}
            buyerDetail={item}
          />
        ))}
      </Stack>
      <PaginationItem
        page={page}
        setPage={(page) => redirect(PageRouteName.ProfileManageStudentPage, { page, name: searchName })}
        totalPage={Math.ceil(Number(courseBuyerListByAdminRes?.total) / Number(courseBuyerListByAdminRes?.pageSize)) || 1}
      />
    </WrapperStyled>
  );
});

const WrapperStyled = styled(Stack)`

`;

export default ProfileStudent;
