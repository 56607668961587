import React, { useState } from "react";
import styled from "styled-components";

// HOOKS
import useRoute from "hooks/useRoute";
import { useSearchParams } from "react-router-dom";
import { useTranslation } from "react-i18next";

// COMPONENTS
import { Stack, useTheme } from "@mui/material";
import ProfileSearchNavbar from "./ProfileSearchNavbar";
import DialogSlide from "components/modals/DialogSlide";
import PaginationItem from "views/PaginationItem";
import UserItem from "../items/UserItem";

// APIS
import { useUserListResApi } from "apis/authApis/useAuthApis";

// CONSTANTS
import { PageRouteName } from "globals/enums/routes.enum";
import { UserDto } from "models/classes/auth.class";
import { actionResetPasswordByAdminApi, actionUpdateByAdminApi } from "apis/authApis/authAction";
import { useSnackbarContext } from "store/snackbarContext";

const ProfileUser = React.memo(() => {
  // instances
  const { t } = useTranslation();
  const theme = useTheme();
  const { redirect } = useRoute();
  const [searchParams] = useSearchParams();
  const searchName = searchParams.get('name') ? String(searchParams.get('name')) : '';
  const page = searchParams.get('page') ? Number(searchParams.get('page')) : 1;

  // states
  const [isDialogReset, setIsDialogReset] = useState(false);
  const [isDialogActive, setIsDialogActive] = useState(false);
  const [selectedUser, setSelectedUser] = useState<UserDto | null>(null);

  // hooks
  const { userListRes, fetchUserListResByApi } = useUserListResApi({ searchName, page });
  const { updateSnackbar } = useSnackbarContext();

  // functions
  const onSubmitSearch = (value: string) => {
    let queryParams = {};
    queryParams['page'] = 1;
    queryParams['name'] = value;
    redirect(PageRouteName.ProfileUserPage, queryParams);
  };

  const handleResetPassword = async () => {
    try {
      setIsDialogReset(false);
      const data = await actionResetPasswordByAdminApi({
        id: selectedUser?.id,
        newPassword: '123456x@X',
      });
      if (data) {
        updateSnackbar({ type: 'success', message: 'Update success', open: true });
      } else {
        updateSnackbar({ type: 'error', message: 'Update fail', open: true });
      }
    } catch (error) {
      console.log('handleResetPassword', error);
    }
  };

  const handleUpdateActive = async () => {
    try {
      setIsDialogActive(false);
      const data = await actionUpdateByAdminApi({
        id: selectedUser?.id,
        isActive: selectedUser?.isActive ? false : true,
      });
      if (data) {
        updateSnackbar({ type: 'success', message: 'Update success', open: true });
        fetchUserListResByApi();
      } else {
        updateSnackbar({ type: 'error', message: 'Update fail', open: true });
      }
    } catch (error) {
      console.log('handleResetPassword', error);
    }
  };

  return (
    <WrapperStyled
      padding={{ xs: '1rem', md: '1rem 2rem' }}
      bgcolor={theme.palette.default.dark}
      width={{ xs: '-webkit-fill-available' }}
      flexDirection="column"
      alignItems="center"
      borderRadius={3}
      rowGap={{ xs: '1.5rem' }}
    >
      <ProfileSearchNavbar
        searchName={searchName}
        onSubmitSearch={onSubmitSearch}
        onClickButton={() => redirect(PageRouteName.ProfileManageStudentCreatePage)}
      />
      <Stack flexDirection="column" rowGap={{ xs: '1.5rem' }} width={{ xs: '100%' }}>
        {(userListRes?.list || [])?.map((item) => (
          <UserItem
            key={item.id}
            userDetail={item}
            setUpdateActive={(user) => {
              setSelectedUser(user);
              setIsDialogActive(true);
            }}
            setUpdatePassword={(user) => {
              setSelectedUser(user);
              setIsDialogReset(true);
            }}
          />
        ))}
      </Stack>
      <DialogSlide
        label="Information"
        open={isDialogReset}
        onClose={(value) => setIsDialogReset(value)}
        onConfirm={handleResetPassword}
      >
        <Stack
          flexDirection={{ xs: 'column' }}
          rowGap={{ xs: '1rem' }}
          fontWeight={300}
          color={theme.palette.text.light}
        >
          {t('Do you want to reset password')}
        </Stack>
      </DialogSlide>
      <DialogSlide
        label="Information"
        open={isDialogActive}
        onClose={(value) => setIsDialogActive(value)}
        onConfirm={handleUpdateActive}
      >
        <Stack
          flexDirection={{ xs: 'column' }}
          rowGap={{ xs: '1rem' }}
          fontWeight={300}
          color={theme.palette.text.light}
        >
          {t('Do you want to update status')}
        </Stack>
      </DialogSlide>
      <PaginationItem
        page={page}
        setPage={(page) => redirect(PageRouteName.ProfileUserPage, { page, name: searchName })}
        totalPage={Math.ceil(Number(userListRes?.total) / Number(userListRes?.pageSize)) || 1}
      />
    </WrapperStyled>
  );
});

const WrapperStyled = styled(Stack)`

`;

export default ProfileUser;
