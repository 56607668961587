import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

// FORMS
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from 'react-hook-form';
import * as yup from "yup";

// HOOKS
import { useSnackbarContext } from "store/snackbarContext";

// COMPONENTS
import { Box, Stack, useTheme } from "@mui/material";
import Button from "components/formControls/Button";
import TextField from "components/formHooks/TextField";

// CONSTANTS
import { actionAuthUpdatePasswordApi } from "apis/authApis/authAction";
import { UserInfoReq } from "models/classes/auth.class";

const ProfilePassword = React.memo(() => {
  // Instance
  const { t } = useTranslation();
  const theme = useTheme();

  // States
  const passwordSchema = yup.object({
    newPassword: yup.string().required(t('This value cannot be blank')).max(50).nullable(),
    confirmNewPassword: yup.string().required(t('This value cannot be blank')).max(50).nullable(),
  });

  const { control, handleSubmit } = useForm<any>({
    resolver: yupResolver(passwordSchema),
  });

  // refs
  const isLoadingUpdate = useRef(false);

  // hooks
  const { updateSnackbar } = useSnackbarContext();

  const handlePassword = async (values: UserInfoReq) => {
    if (isLoadingUpdate.current) return;
    if (values.confirmNewPassword !== values.newPassword) {
      updateSnackbar({ open: true, type: 'error', message: 'Passwords are not the same' });
      return;
    }
    isLoadingUpdate.current = true;
    const data = await actionAuthUpdatePasswordApi(values);
    if (data) {
      updateSnackbar({ open: true, type: 'success', message: 'Update success' });
    } else {
      updateSnackbar({ open: true, type: 'error', message: 'Update fail' });
    }
    isLoadingUpdate.current = false;
  };

  return (
    <WrapperStyled
      padding={{ xs: '2rem' }}
      bgcolor={theme.palette.default.dark}
      width={{ xs: '-webkit-fill-available' }}
      flexDirection="column"
      rowGap={{ xs: '1.5rem' }}
      borderRadius={3}
    >
      <Stack flexDirection={{ xs: 'row' }} justifyContent={{ xs: 'space-between' }} mb={{ xs: '1rem' }}>
        <Box
          fontSize={{ xs: 24, md: 30 }}
          color={theme.palette.text.light}
        >
          {t('Change Password')}
        </Box>
        <Button color="primary" style={{ fontWeight: 300 }} onClick={handleSubmit(handlePassword)}>{t('Update')}</Button>
      </Stack>
      <Stack flexDirection={{ xs: 'row' }} columnGap={{ xs: '2rem' }} width={{ xs: '100%' }}>
        <Stack flex={1} rowGap={{ xs: '.5rem' }}>
          <Box color={theme.palette.text.light}>{t('New password')}</Box>
          <TextField
            control={control}
            name="newPassword"
            typeInput="password"
            placeholder={t('Enter your new password here')}
            background={theme.palette.default.light}
            style={{ width: '100%' }}
          />
        </Stack>
        <Stack flex={1} rowGap={{ xs: '.5rem' }}>
          <Box color={theme.palette.text.light}>{t('Confirm new password')}</Box>
          <TextField
            control={control}
            name="confirmNewPassword"
            typeInput="password"
            placeholder={t('Enter your confirm new password here')}
            background={theme.palette.default.light}
            style={{ width: '100%' }}
          />
        </Stack>
      </Stack>
    </WrapperStyled>
  );
});

const WrapperStyled = styled(Stack)`

`;

export default ProfilePassword;
