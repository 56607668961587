import { isEmpty } from "lodash";
import { useEffect } from "react";

// HOOKS
import useAbortRequest from "hooks/useAbortRequest";
import {
  actionCourseDescriptionDetailApi,
  actionCourseDescriptionListApi,
} from "./courseDescriptionAction";
import { useCourseDescriptionContext } from "./courseDescriptionContext";
import { FetchCourseDescriptionParams } from "./courseDescriptionParam";

export const useCourseDescriptionListByApi = ({
  coursePersonalId,
  searchName,
  page,
}: FetchCourseDescriptionParams) => {
  const { newAbortSignal, abortRequest } = useAbortRequest();

  const { courseDescriptionListRes, updateCourseDescriptionListRes } =
    useCourseDescriptionContext();

  const fetchCourseDescriptionListByApi = async () => {
    await abortRequest();
    const data = await actionCourseDescriptionListApi({
      page,
      searchName,
      coursePersonalId,
      newAbortSignal,
    });
    if (!isEmpty(data)) {
      updateCourseDescriptionListRes(data!);
    }
  };

  // Unmout Apis
  useEffect(() => {
    if (isEmpty(courseDescriptionListRes) || searchName || page) {
      fetchCourseDescriptionListByApi();
    }
    return () => {
      (async () => {
        await abortRequest();
      })();
    };
  }, [searchName, page]);

  return {
    courseDescriptionListRes,
    fetchCourseDescriptionListByApi,
  };
};

export const useCourseDescriptionDetailByApi = ({
  coursePersonalId,
  courseDescriptionId,
}: FetchCourseDescriptionParams) => {
  const { newAbortSignal, abortRequest } = useAbortRequest();

  const { courseDescription, updateCourseDescription } =
    useCourseDescriptionContext();

  const fetchCourseDescriptionDetailByApi = async () => {
    await abortRequest();
    const data = await actionCourseDescriptionDetailApi({
      coursePersonalId,
      courseDescriptionId,
      newAbortSignal,
    });
    updateCourseDescription(data!);
  };

  // Unmout Apis
  useEffect(() => {
    if (courseDescriptionId && coursePersonalId) {
      fetchCourseDescriptionDetailByApi();
    }
    return () => {
      (async () => {
        await abortRequest();
      })();
    };
  }, [courseDescriptionId]);

  return {
    courseDescription: courseDescriptionId ? courseDescription : null,
    fetchCourseDescriptionDetailByApi,
  };
};
