import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import { isEmpty } from "lodash";

// HOOKS
import useLoadingCallback from "hooks/useLoadingCallback";
import useRoute from "hooks/useRoute";
import { useSnackbarContext } from "store/snackbarContext";

// COMPONENTS
import { Box, Stack, useTheme } from "@mui/material";
import Button from "components/formControls/Button";

// UNTILS
import TextField from "components/formControls/TextField";
import { IsCreate, IsEdit } from "globals/enums/courses.enum";

// ICONS
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import { baseImageURL } from "apis/configs/requestApis";
import { fetchCoursePersonalUploadApi } from "apis/coursePersonalApis/coursePersonalFetch";
import { validationFile, validationSize } from "utils/dataHelpers";
import { useCategoryDetailByApi } from "apis/courseCategoryApis/useCourseCategoryApis";
import { CourseCategoryData } from "models/classes/courseCategory.class";
import { actionCourseCategoryCreateApi, actionCourseCategoryUpdateApi } from "apis/courseCategoryApis/courseCategoryAction";

const ProfileCategoriesCreate = React.memo(() => {
  // instance
  const { t } = useTranslation();
  const theme = useTheme();
  const { goBack, params } = useRoute();
  const isView = params.id ? IsEdit : IsCreate;

  // refs
  const imageRef = useRef<HTMLInputElement>(null);

  // states
  const [name, setName] = useState('');
  const [image, setImage] = useState('');

  // hooks
  const { updateSnackbar } = useSnackbarContext();
  const { categoryDetail } = useCategoryDetailByApi({ courseCategoryId: params.id });

  // functions
  const [handleCreate, isLoadingCreate] = useLoadingCallback(async () => {
    try {
      if (!image) {
        updateSnackbar({ type: 'error', message: 'Enter your image here', open: true });
        return;
      } else if (!name) {
        updateSnackbar({ type: 'error', message: 'Enter your name category', open: true });
        return;
      }
      const values: CourseCategoryData = {
        name,
        image,
      };
      const data = await actionCourseCategoryCreateApi(values);
      if (data) {
        updateSnackbar({ type: 'success', message: 'Create success', open: true });
        goBack();
      } else {
        updateSnackbar({ type: 'error', message: 'Create fail', open: true });
      }
    } catch (error) {
      console.log('handleCreate', error);
    }
  });

  const [handleUpdate, isLoadingUpdate] = useLoadingCallback(async () => {
    try {
      if (!image) {
        updateSnackbar({ type: 'error', message: 'Enter your image here', open: true });
        return;
      } else if (!name) {
        updateSnackbar({ type: 'error', message: 'Enter your name category', open: true });
        return;
      }
      const values: CourseCategoryData = {
        name,
        image,
      };
      const data = await actionCourseCategoryUpdateApi(params.id, values);
      if (data) {
        updateSnackbar({ type: 'success', message: 'Create success', open: true });
        goBack();
      } else {
        updateSnackbar({ type: 'error', message: 'Create fail', open: true });
      }
    } catch (error) {
      console.log('handleUpdate', error);
    }
  });

  // functions
  const handleUpload = async (file: any) => {
    if (!isEmpty(file)) {
      const newFile = file[0];
      if (validationSize(newFile)) {
        if (validationFile(newFile)) {
          const { filename } = await fetchCoursePersonalUploadApi(newFile) as any;
          if (filename) {
            setImage(`${baseImageURL}/${filename}`);
          } else {
            updateSnackbar({ type: 'error', message: 'Server internal error', open: true });
          }
        } else {
          updateSnackbar({ type: 'error', message: 'Uploaded data must be an image', open: true });
        }
      } else {
        updateSnackbar({ type: 'error', message: 'Images must be less than 1MB', open: true });
      }
    }
    if (imageRef.current) {
      imageRef.current.value = "";
    }
  };

  // effects
  useEffect(() => {
    if (!isEmpty(categoryDetail)) {
      setName(categoryDetail?.name!);
      setImage(categoryDetail?.image!);
    }
  }, [categoryDetail]);

  return (
    <WrapperStyled
      padding={{ xs: '2rem' }}
      bgcolor={theme.palette.default.dark}
      width={{ xs: '-webkit-fill-available' }}
      flexDirection={{ xs: 'column' }}
      rowGap={{ xs: '1.5rem' }}
      borderRadius={3}
    >
      <Stack flexDirection={{ xs: 'row' }} justifyContent={{ xs: 'space-between' }} mb={{ xs: '1rem' }}>
        <Box
          fontSize={{ xs: 24, md: 30 }}
          color={theme.palette.text.light}
        >
          {t('Create category')}
        </Box>
        <Button
          color="primary"
          style={{ fontWeight: 300 }}
          disabled={isLoadingUpdate || isLoadingCreate}
          onClick={isView === IsEdit ? handleUpdate : handleCreate}
        >
          {t('Confirm')}
        </Button>
      </Stack>
      <Stack flexDirection={{ xs: 'row' }} columnGap={{ xs: '2rem' }} width={{ xs: '100%' }}>
        <Stack flex={1} rowGap={{ xs: '.5rem' }}>
          <Box color={theme.palette.text.light}>{t('Image')}</Box>
          <Stack flexDirection={{ xs: 'row' }} columnGap={{ xs: '1rem' }}>
            <Box width="100%">
              <TextField
                maxLength={250}
                value={image}
                placeholder={t('Enter your image here')}
                background={theme.palette.default.light}
                style={{ width: '100%' }}
                InputProps={{
                  readOnly: true,
                }}
                required
              />
              <input onChange={(e) => handleUpload(e.target.files)} ref={imageRef} type="file" style={{ display: 'none' }} />
            </Box>
            <Button color="primary" onClick={() => imageRef.current?.click()}>
              <AddPhotoAlternateIcon style={{ color: theme.palette.text.light }} />
            </Button>
          </Stack>
        </Stack>
        <Stack flex={1} rowGap={{ xs: '.5rem' }}>
          <Box color={theme.palette.text.light}>{t('Name category')}</Box>
          <TextField
            placeholder={t('Enter your name category')}
            background={theme.palette.default.light}
            style={{ width: '100%' }}
            value={name || ''}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </Stack>
      </Stack>
    </WrapperStyled>
  );
});

const WrapperStyled = styled(Stack)`

`;

export default ProfileCategoriesCreate;
